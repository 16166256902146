<template>
    <v-app>
        <!-- <v-syste
            m-bar> -->

        <!-- </v-system-bar> -->
        <AppHeaderComp />

        <v-main>
            <h2>{{ club?.name | Lg(lg) }}</h2>
            <!--       <v-btn @click="$router.go(-1)">go back</v-btn>
      <v-btn @click="$router.go(+1)">go next</v-btn> -->
            <!-- {{myAlertObjs}} -->
            <!-- <DialogGeneralModelMyAlertComp></DialogGeneralModelMyAlertComp> -->
            <AppMenuComp />
            <v-container>
                <router-view :key="`${$route.path}-${lg}`" />
                <!-- <router-view/> -->
                <!--               999999999
               {{urlServer}}
               ******* -->
                <!-- <TheComp :item='TheComp_example'></TheComp> -->
            </v-container>
            <!--           <modal name="my-first-modal">
        This is my first modal {{myAlertObjs}}
    </modal> -->
            <!--                               <WBC
                                        :item="{
                                                  comp: 'PlayerTimeTableUi',
                                                  props:{}
                                        }"
                              ></WBC> -->
        </v-main>
        <AppFooterComp />
        <!-- <v-row justify="center">
    <v-dialog
      v-model="dialog" max-width="600px"hide-overlay transition="diafdddlog-bottom-transition">
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="hideAlert"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader>User Controls</v-subheader>
          ccccccccccc{{myAlertObjs}}aaaaaaaaaaaaaaaa
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Content filtering</v-list-item-title>
              <v-list-item-subtitle>Set the content filtering level to restrict apps that can be downloaded</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Password</v-list-item-title>
              <v-list-item-subtitle>Require password for purchase or use password to restrict purchase</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
          three-line
          subheader
        >
          <v-subheader>General</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="notifications"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <v-list-item-subtitle>Notify me about updates to apps or games that I downloaded</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="sound"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sound</v-list-item-title>
              <v-list-item-subtitle>Auto-update apps at any time. Data charges may apply</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="widgets"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Auto-add widgets</v-list-item-title>
              <v-list-item-subtitle>Automatically add home screen widgets</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row> -->
    </v-app>
</template>
<script>
import * as vuetifyModule from "vuetify/lib";
import Vuex from "vuex";

export default {
    title: (thiss) => {
        return {
            en: "Home page",
            fr: "Page d'accueil",
            ar: "الصفحة الرئيسية",
        }[thiss.lg];
    },
    data() {
        return {
            alertKey: "alertKey",
            a: 111,
            dialog: false,
            dataConsole: [],
            //                 TheComp_example: {comp:['a','b'],///title////
            // props: {
            // key: 'key-season-sec'},
            // a:{
            // comp: "VCard",
            // props: {
            // key: 'key-season-sec',
            // // hide: thiss => {
            // // let u = thiss.user;
            // // return !(u.is_staff | u.is_federation | u.is_governoment | u.is_ministry | u.is_club)
            // // },
            // html: 'ddddddddddddddd',
            // class: "red text-h5 justify-center text-darken-1 my-5 ",
            // // style: {},
            // // html: thiss => { return { en: 'Club choice', fr: 'Choix du club', ar: 'اختيار النادي' } [thiss.lg] },
            // }},
            // b:'[[bla bla bla||aaaaaaaaaaa]]'
            // }
        };
    },
    components: {
        AppHeaderComp: () => import("./components/appHeader/AppHeaderComp.vue"),
        AppMenuComp: () => import("./components/appMenu/AppMenuComp.vue"),
        AppFooterComp: () => import("./components/appFooter/AppFooterComp.vue"),
        // DialogGeneralModelMyAlertComp: () => import("@/components/generalComps/myAlertComp/DialogGeneralModelMyAlertComp")
    },
    methods: {
        ...Vuex.mapActions(["setLang"]),
        //     hideAlert(){
        //       this.$store.commit('updateMyAlertCondition', false)
        // this.$store.commit('updateMyAlertObjs', {})
        //     },
        // show () {
        //         this.$modal.show('my-first-modal');
        //     },
        //     hide () {
        //         this.$modal.hide('my-first-modal');
        //     }
    },
    computed: {
        ...Vuex.mapGetters([
            "urlServer",
            "user",
            "loggedIn",
            "lg",
            "headers",
            "globalLibelle",
            "token",
            "cryptoKey",
            "initialCryptoKey",
            "myAlertCondition",
            "myAlertObjs",
            "myAlertObjsTitles",
        ]),
        // mAlertCondition: function (){
        //   this.alertKey+=1
        //   return JSON.parse((this.$cookies.get('mAlertCondition')));
        // }
    },
    watch: {
        // dialog(v){
        //   this.dialog=v;
        //   this.$store.commit('myAlertCondition', v)
        // }
        // myAlertCondition(v){
        //   this.dialog=v;
        //   this.$store.commit('myAlertCondition', v)
        // }
    },
    created() {
        const link = document.querySelector("[rel='icon']");
        link.setAttribute("href", "http://www.ftt.tn/images/logoClub/Logo_TCMO.jpg");
        link.setAttribute("Atype", "image/x-icon");

        this.tokenAccessCheck();
    },
    mounted() {
        // this.show()
        // this.dialog=this.myAlertCondition;
        // alert(this.$cookies.get('lg'));
        if (!this.$cookies.get("lg")) {
            this.$cookies.set("lg", "en");
        }
        this.$store.commit("updateLang", this.$cookies.get("lg"));
        global.v = this;
        // },
        /////////////////Check if user is still login/////////////////
    },
    computed: {
        ...Vuex.mapGetters([
            "urlServer",
            "user",
            "profile",
            "loggedIn",
            "lg",
            "headers",
            "globalLibelle",
            "token",
            "cryptoKey",
            "club",
        ]),
    },
};
</script>
