<template>
  <span>
    <template v-if="!Array.isArray(styling)">
      {{ styling }}
    </template>
    <template v-for="aHtml in styling" v-else>
      <template v-if="['null', 'undefined'].includes(aHtml[0])"> - </template>
      <template v-else-if="aHtml.length >= 3">
        <WBLink :to="strToObj(aHtml[2])">
          <span v-html="aHtml[0]" v-bind="{ class: aHtml[1] } || {}"></span>
        </WBLink>
      </template>
      <span v-html="aHtml[0]" v-else-if="aHtml.length == 2" v-bind="{ class: aHtml[1] } || {}"></span>
      <span v-html="aHtml[0]" v-else></span>
    </template>
  </span>
</template>
<script>
// import { RouterLink } from 'vue-router'
// import VueRouter from "vue-router";
export default {
  name: "WBHtml",
  props: {
    html: null,
    // {
    //   type:null,
    //   default:()=>`Text [[Sec|http://www.aa.com|text-h1 justify-center text-darken-1 my-15 red]] With Link.[[bonjour tout le <i><u>monde</u></i>|www.bb.com|text-h1 justify-center text-darken-1 my-15 blue]]`
    // }
  },
  data() {
    return {
      html_: null,
    };
  },
  methods: {
    strToObj(str) {
      try {
        return JSON.parse(str);
      } catch (error) {
        return str;
      }
    },
  },
  computed: {
    styling() {
      if (typeof this.html_ !== "string") return this.html_;
      if (typeof this.html_ == "string" && this.html_.includes("[[") && this.html_.includes("]]")) {
        return this.html_
          .replace(/\[\[/g, "**")
          .replace(/\]\]/g, "**")
          .split("**")
          .map((h) => h.split("|"));
      }
      return this.html_;
    },
  },
  mounted() {
    this.html_ = this.html;
  },
};
</script>
<style lang="css" scoped></style>
