import { render, staticRenderFns } from "./WBLink.vue?vue&type=template&id=0231d0d0&scoped=true&"
import script from "./WBLink.vue?vue&type=script&lang=js&"
export * from "./WBLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0231d0d0",
  null
  
)

export default component.exports