import Vue from "vue";
import App from "./app/App.vue";
import { h, render } from "vue";
import router from "./router";
import { Buffer } from "buffer";
// globalThis.Buffer = Buffer;

import store from "./store";

import vuetify from "./plugins/vuetify";
import * as vuetifyModule from "vuetify/lib";

//////////////////////////////////////////////////
// console.log('***************************',WBC_pluging.WBC,store);
// console.log('%c__________GroupUi.js-line,178:__________\n','color:red;font-size:,30px;',
// theApp, '\n__________\n',
// WBC_pluging.WBC, '\n__________\n',
// theApp.options.computed, '\n__________\n',
// WBC_pluging.WBC.components.DialogGeneralModelMyAlertComp, '\n__________\n',
// '','\n__________End__________')

///////////////////////////////////////////////////////////////////////
///////////////////////////WBC component///////////////////////////
///////////////////////////////////////////////////////////////////////

// import WBC_pluging from "./myPackages/wbc-ui2_v1.1.0-r1";
import WBC_pluging from "./myPackages/wbc-ui2_v1.1.7";
// import WBC_pluging from "wbc-ui2";
// let p = require("process");

// let ff = () => {
// };
// ff();

Vue.use(WBC_pluging, { components: { ...vuetifyModule }, computed: store._wrappedGetters }, "prod");

// let WBC=WBC_pluging.WBC;
// WBC.computed={...WBC.computed,...store._wrappedGetters}
// _wrappedGetters

// let WBC = () => import('@/components/theComps/theCompComp/WBC.vue')
// let TheComp = () => import('@/components/theComps/theCompComp/TheComp.vue')
// Vue.component('WBC', WBC)
// Vue.component('WBHtml', WBC_pluging.WBHtml)
// Vue.component('WBLink', WBC_pluging.WBLink)

let myRouterLink = () => import("@/components/theComps/myRouterLinkComp/myRouterLink.vue");
Vue.component("WBLink", myRouterLink);

///////////////////////////////////////////////////////////////////////
///////////////////////////Schedule component///////////////////////////
///////////////////////////////////////////////////////////////////////

// import WBSchedule_pluging from "./myPackages/wb-schedule2_v1.0.1";
// Vue.use(WBSchedule_pluging, {...vuetifyModule},'prod');
import WBSchedule_pluging from "wb-schedule2";
Vue.use(WBSchedule_pluging, { components: { ...vuetifyModule }, computed: store._wrappedGetters });

// let ScheduleComp=()=> import("@/components/scheduleComps/ScheduleComp.vue");
// Vue.component('ScheduleComp',ScheduleComp)

// let TheGeneralScheduleComp = () =>
// import("@/components/scheduleComps/TheGeneralScheduleComp.vue");
// Vue.component("TheGeneralScheduleComp", TheGeneralScheduleComp);
// Vue.component("TheGeneralScheduleComp", WBSchedule_pluging.WBSchedule);

// import TheComp_pluging from "@/myPackages/the-comp-v1.0.1";
// Vue.use(TheComp_pluging, {});

import underscore from "vue-underscore";
Vue.use(underscore);

import mdi from "@/plugins/Mdi";
Vue.use(mdi);

import Storage from "@/plugins/Storage";
Vue.use(Storage);
import variables from "@/globals/variables";
let randomColor = variables.randomColor;
let randomKey = variables.randomKey;
let capitalizeWords = variables.capitalizeWords;
let getRandomInt = variables.getRandomInt;
let stringToJson = variables.stringToJson;
let compFunc = variables.compFunc;
// let colorLibelle = variables.colorLibelle;
// let  colorLibelle=['red', 'blue', "#2B2E4A", "#521262", "#903749", "#53354A", "#40514E", "#537780",   "yellow"];
// let o=require('./globals/tools')

// let get_attrs=require('./globals/tools')
// get_attrs(vuetify,555,3)
// let ch='ddfdsf';
// let a={a:1,b:20};
// let aa=()=>true;
// alert(aa.depthOf(a))
import VuetifyJetValidator from "@/rules/MyRules.js";
Vue.prototype.validator = new VuetifyJetValidator();

Vue.prototype.$window = window;

let ModelMyAlertComp = () => import("@/components/theComps/myAlertComp/ModelMyAlertComp.vue");
Vue.component("ModelMyAlertComp", ModelMyAlertComp);

let TheModelMyAlertComp = () => import("@/components/theComps/myAlertComp/TheModelMyAlertComp.vue");
Vue.component("TheModelMyAlertComp", TheModelMyAlertComp);

let DialogGeneralModelMyAlertComp = () => import("@/components/theComps/myAlertComp/DialogGeneralModelMyAlertComp.vue");
Vue.component("DialogGeneralModelMyAlertComp", DialogGeneralModelMyAlertComp);

Vue.component("testcomponent", {
    render: function (createElement) {
        var a = this.elementtype.split(",");
        return createElement(
            a[0],
            {
                attrs: {
                    id: a[3],
                    style: "color:" + a[1] + ";font-size:" + a[2] + ";",
                },
            },
            this.$slots.default
        );
    },
    props: {
        elementtype: {
            attributes: String,
            required: true,
        },
    },
});

Vue.component("test1", {
    render() {
        return h("div", ["Welcome to GeeksforGeeks", "Welcome to GeeksforGeeks", "Welcome to GeeksforGeeks"]);
    },
    // render() {
    //           render: h => h('div',{},7777777777777777)
    //           return h('div', {
    //                     class: 'bar',
    //                     innerHTML: 'hello',
    //                     id: 'id_1'
    //           })
    // },
    props: {},
});

// Vue.component('some-component', {
//     data: function() {
//         return {
//             heading: 'Some Component'
//         };
//     },
//     template: '<h1>{{heading}}</h1>'
// });

// Vue.component('heading', {

//     template: '<div id="foo">bar</div>'
// })
// Vue.component('heading', {
//   render (h) { // <-- h must be in scope
//     return '<div id="foo">bar</div>'
//   }
// })
// Vue.component('heading', {
//   // props: ['title'],
//   template: `
//   <overridable name="heading">
//     <h1>
//      xxxxxxxxxxxxxxxxxxxxxxxx
//     </h1>
//   </overridable>`
// });
// Vue.component('jsx-example', {
//   data() {
//     // return { checked: false, title: 'Check me' }
//   },
//   methods: {
//     // check() { this.checked = !this.checked; }
//   },
//   template:`sssssssssssssss`
//   // render() {
//   //   return `<div class="checkbox-wrapper" onClick={ this.check }>
//   //               <div class={{ checkbox: true, checked: this.checked }}></div>
//   //               <div class="title">{ this.title }</div>
//   //             </div>`
//   // }
// })

let TheGeneralObjectComp = () => import("@/components/theComps/theGeneralObjectsComp/TheGeneralObjectComp.vue");
Vue.component("TheGeneralObjectComp", TheGeneralObjectComp);

let TheItemsTableComp = () => import("@/components/theComps/theItemsTableComp/TheItemsTableComp.vue");
Vue.component("TheItemsTableComp", TheItemsTableComp);

let TheItemsTranspTableComp = () => import("@/components/theComps/theItemsTableComp/TheItemsTranspTableComp.vue");
Vue.component("TheItemsTranspTableComp", TheItemsTranspTableComp);

// let GlobalSectionComp=()=> import("@/components/theComps/generalObjectsComp/GlobalSectionComp.vue");
// Vue.component('GlobalSectionComp',GlobalSectionComp)

let TheItemsSectionsComp = () => import("@/components/theComps/theItemsSectionsComp/TheItemsSectionsComp.vue");
Vue.component("TheItemsSectionsComp", TheItemsSectionsComp);

let ThePwdChangeComp = () => import("@/views/ui/account/profile/components/pwdChangeComp/ThePwdChangeComp.vue");
Vue.component("ThePwdChangeComp", ThePwdChangeComp);

let TheAccountUpdateComp = () =>
    import("@/views/ui/account/profile/components/accountUpdateComp/TheAccountUpdateComp.vue");
Vue.component("TheAccountUpdateComp", TheAccountUpdateComp);

let TheProfileUpdateComp = () =>
    import("@/views/ui/account/profile/components/profileUpdateComp/TheProfileUpdateComp.vue");
Vue.component("TheProfileUpdateComp", TheProfileUpdateComp);

let ThePlayerInfoComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerInfoComp/ThePlayerInfoComp.vue");
Vue.component("ThePlayerInfoComp", ThePlayerInfoComp);

let ThePlayerSessionsComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerSessionsComp/ThePlayerSessionsComp.vue");
Vue.component("ThePlayerSessionsComp", ThePlayerSessionsComp);

let ThePlayerTrackingComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerTrackingComp/ThePlayerTrackingComp.vue");
Vue.component("ThePlayerTrackingComp", ThePlayerTrackingComp);

let ThePlayerGoalsComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerGoalsComp/ThePlayerGoalsComp.vue");
Vue.component("ThePlayerGoalsComp", ThePlayerGoalsComp);

let ThePlayerSettingsComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerSettingsComp/ThePlayerSettingsComp.vue");
Vue.component("ThePlayerSettingsComp", ThePlayerSettingsComp);

let ThePlayerSubscriptionsComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerSubscriptionsComp/ThePlayerSubscriptionsComp.vue");
Vue.component("ThePlayerSubscriptionsComp", ThePlayerSubscriptionsComp);

let ThePlayerAdministrationsComp = () =>
    import(
        "@/views/detailsPages/player/player/components/thePlayerAdministrationsComp/ThePlayerAdministrationsComp.vue"
    );
Vue.component("ThePlayerAdministrationsComp", ThePlayerAdministrationsComp);

let ThePlayerStatisticsComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerStatisticsComp/ThePlayerStatisticsComp.vue");
Vue.component("ThePlayerStatisticsComp", ThePlayerStatisticsComp);

let ThePlayerUpdatingComp = () =>
    import("@/views/detailsPages/player/player/components/thePlayerUpdatingComp/ThePlayerUpdatingComp.vue");
Vue.component("ThePlayerUpdatingComp", ThePlayerUpdatingComp);

/////////////////////////
let PlayerTimeTableUi = () => import("@/views/ui/timetable/weekly/player/PlayerTimeTableUi.vue");
Vue.component("PlayerTimeTableUi", PlayerTimeTableUi);
/////////////////////////

let Login = () => import("@/views/ui/account/login/Login.vue");
Vue.component("Login", Login);

let Register = () => import("@/views/ui/account/register/Register.vue");
Vue.component("Register", Register);
let NightMode = () => import("@/views/settings/nightMode/NightMode.vue");
Vue.component("NightMode", NightMode);

// let GeneralCompSetComp=()=> import("@/components/theComps/generalCompSetComp/GeneralCompSetComp.vue");
// Vue.component('GeneralCompSetComp',GeneralCompSetComp)

// import * as e from "vuetify/lib";
// e=() => import(`vuetify/lib`)

// console.log('7777777777777777777777',e);
// _.mapObject(e,(v,k)=>{
// alert(k+typeof v);
// Vue.component(k,vv)
// })
// Vue.component={...Vue.component,...e}
// Vue.component(
//     {VCard,
//         VSelect,
//         VTextField,
//         VCheckbox,
//         VBtn,
//         VForm,
//         VTabs,
//         VTab,
//         VTabsBar,
//         VTabItem,
//         VTabsItems,
//         VImg,
//         VDivider,
//         VCol,
//         VRow,
//         VCardTitle,
//         VCardSubtitle,
//         VCardText,
//         VCardActions,
//         VList,
//         VListItem,
//         VListItemGroup,
//         VContainer,
//         VToolbar,
//         VAppBarNavIcon,
//         VToolbarTitle,
//         VSpacer,
//         VIcon,
//         VDataTable,
//         VSheet,
//         VCalendar}
//     );

// MyNestedComp=()=> import("@/components/theComps/myNestedComp/MyNestedComp.vue");
// Vue.component('MyNestedComp',MyNestedComp)

// let ItemSetComp=()=> import("@/components/theComps/generalCompSetComp/ItemSetComp.vue");
// Vue.component('ItemSetComp',ItemSetComp)

// ShortCutComp=()=> import("@/components/theComps/shortCutComp/ShortCutComp.vue");
// Vue.component('ShortCutComp',ShortCutComp)

// Vue.prototype.$aaa = `this.$store.dispatch('myAlert',{a:'thiss.user',b:'thiss.user',c:'thiss.user'});`;

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import VueResource from "vue-resource";
Vue.use(VueResource);

import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);

import titleMixin from "./mixins/titleMixin";
Vue.mixin(titleMixin);

import VueMoment from "vue-moment";
import Moment from "moment-timezone";

// import Moment from 'moment';
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

Vue.use(VueMoment, {
    moment,
});

global.moment = moment;
import { _ } from "vue-underscore";
global._ = _;
global.JsonViewer = JsonViewer;
global.vuetify = vuetify;
global.Vue = Vue;

import JsonViewer from "vue-json-viewer";
// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer);

import VModal from "vue-js-modal";
Vue.use(VModal);

///////////////////////////////////
Vue.config.productionTip = false;

//////////////filter//////////////////
//appropriate langage
Vue.filter("Lg", function (v, lg) {
    if (v.constructor === String) {
        return v;
    }
    if (v.constructor === Object) {
        return lg in v ? v[lg] : `"${v["en"]}" to translate to "${lg}"`;
    }
    // return v
});

//////////////plugin//////////////////
// // calls `MyPlugin.install(Vue)`
// const plugin = {
//   install() {
//     // Vue.gPluginFun = () => 'this is a plugin test' //Vue.gPluginFun()
//     // Vue.prototype.capitalizeWords = (v) => v.replace(/_./, str => str.toUpperCase()).replace(/^./, str => str.toUpperCase()).replace('_', ' ')
//     Vue.prototype.capitalizeWords = (v) => ` ${v}`.replace(/_/g, ' ').replace(/ ./g, str => str.toUpperCase()).trim()
//     // Vue.prototype.$gPluginFun = () => 'thisddddddddddd is a plugin test' //this.$gPluginFun()
//   }
// }
// Vue.use(plugin)

// import VuetifyJetValidator from "@/rules/MyRules.js";
// const validator = new VuetifyJetValidator();

//////////////encryptin/decrypting/////////////////

// calls `MyPlugin.install(Vue)`
const crypting = {
    install() {
        /** * Encryption */
        Vue.prototype.aes_encrypt = function (plaintext, key, iv) {
            key = this.CryptoJS.enc.Utf8.parse(key);
            iv = this.CryptoJS.enc.Utf8.parse(iv);
            let srcs = this.CryptoJS.enc.Utf8.parse(plaintext);
            let encrypted = this.CryptoJS.AES.encrypt(srcs, key, {
                iv: iv,
                mode: this.CryptoJS.mode.CBC,
                padding: this.CryptoJS.pad.Pkcs7,
            });
            return encrypted.ciphertext.toString();
        };

        Vue.prototype.aes_decrypt = function (ciphertext, key, iv) {
            key = this.CryptoJS.enc.Utf8.parse(key);
            iv = this.CryptoJS.enc.Utf8.parse(iv);
            let hex_string = this.CryptoJS.enc.Hex.parse(ciphertext);
            let srcs = this.CryptoJS.enc.Base64.stringify(hex_string);
            let decrypt0 = this.CryptoJS.AES.decrypt(srcs, key, {
                iv: iv,
                mode: this.CryptoJS.mode.CBC,
                padding: this.CryptoJS.pad.Pkcs7,
            });
            let decrypt = decrypt0.toString(this.CryptoJS.enc.Utf8);

            // alert(decrypt);

            return decrypt.toString();
        };
    },
};
Vue.use(crypting);

const MyPlugins = {
    install() {
        // Vue.gPluginFun = () => 'this is a plugin test' //Vue.gPluginFun()
        // Vue.prototype.capitalizeWords = (v) => v.replace(/_./, str => str.toUpperCase()).replace(/^./, str => str.toUpperCase()).replace('_', ' ')
        (Vue.prototype.capitalizeWords = capitalizeWords),
            /*(v) =>
      ` ${v}`
        .replace(/_/g, " ")
        .replace(/ ./g, (str) => str.toUpperCase())
        .trim();*/
            // Vue.prototype.$gPluginFun = () => 'thisddddddddddd is a plugin test' //this.$gPluginFun()

            // Vue.prototype.evalRules = (rulesObj) => {
            //   if (rulesObj)
            //     return rulesObj.map((h) => validator.rules[h.method](h.params));
            //   else return [];
            // };
            (Vue.prototype.fields_proj = function (v, lg) {
                // return this.$options.filters.Proj(v, lg)
                if (v.constructor === Object) {
                    return Object.assign(
                        {},
                        ...Object.keys(v).map((h) => ({
                            [h]: v[h].constructor === Object && Object.keys(v[h]).includes(lg) ? v[h][lg] : v[h],
                        }))
                    );
                } else {
                    return v;
                }
                // return Object.keys(v).map(e => ({ e: v[e][lg] }));
            }),
            (Vue.prototype.getRandomInt = getRandomInt),
            /*function (max) {
        return Math.floor(Math.random() * max);
      })*/
            (Vue.prototype.EventFoncArgRecursive = function (aPreFieldos0) {
                if (aPreFieldos0 instanceof Object && aPreFieldos0.EventFoncArg) {
                    // let aPreFieldos0_i_EventFoncArg0=aPreFieldos0.EventFoncArg;

                    if (typeof aPreFieldos0.EventFoncArg === "function") {
                        aPreFieldos0.EventFoncArg = aPreFieldos0.EventFoncArg(this);
                    } else {
                        for (let eventKey in aPreFieldos0.EventFoncArg) {
                            if (eventKey == "load") {
                                aPreFieldos0.EventFoncArg[eventKey](this);
                            } else {
                                if (!aPreFieldos0.events) {
                                    aPreFieldos0.events = {};
                                }
                                aPreFieldos0["events"][eventKey] = ($event) =>
                                    aPreFieldos0.EventFoncArg[eventKey](this, $event);
                            }
                        }
                    }
                    return aPreFieldos0;
                }

                if (Array.isArray(aPreFieldos0)) {
                    // console.log("+++++++++++++", aPreFieldos0);
                    // let aPreFieldos0Modified=[]
                    for (var i = 0; i < aPreFieldos0.length; i++) {
                        aPreFieldos0[i] = this.EventFoncArgRecursive(aPreFieldos0[i]);
                    }
                    return aPreFieldos0;
                    // for (let item of aPreFieldos0) {
                    //   aPreFieldos0Modified.push(this.EventFoncArgRecursive(item))
                    // };
                    // return aPreFieldos0Modified
                }
                // if (!Array.isArray(aPreFieldos0) & aPreFieldos0.EventFoncArg instanceof Object){

                //         if(typeof aPreFieldos0.EventFoncArg==='function'){
                // aPreFieldos0.EventFoncArg=aPreFieldos0.EventFoncArg(this)
                //         };

                //               for (var eventKey in aPreFieldos0.EventFoncArg) {
                //               if (eventKey == "load") {
                //                 aPreFieldos0.EventFoncArg[eventKey](this);
                //               } else {
                //                 if (!aPreFieldos0.events) { aPreFieldos0.events = {} }
                //                   console.log(aPreFieldos0,'My alert in /home/wissemb11/Allprojects/utilisateur/vue_vuetify_extension_kocal_v1/src/main.js at line 332');
                //                 aPreFieldos0["events"][eventKey] = ($event) =>
                //                   aPreFieldos0.EventFoncArg[eventKey](this, $event);
                //               }
                //             }

                // return aPreFieldos0}
                else if (aPreFieldos0 instanceof Object) {
                    for (let i in aPreFieldos0) {
                        // var a={};
                        if (aPreFieldos0[i].EventFoncArg) {
                            aPreFieldos0[i] = this.EventFoncArgRecursive(aPreFieldos0[i]);
                        }
                    }
                    return aPreFieldos0;
                    // console.log('----------------',aPreFieldos0,typeof aPreFieldos0,Array.isArray(aPreFieldos0));

                    // else if (aPreFieldos0 instanceof Object){
                    //         for (let i in aPreFieldos0) {
                    //            if (aPreFieldos0[i].EventFoncArg) {
                    //         let aPreFieldos0_i_EventFoncArg=aPreFieldos0[i].EventFoncArg;
                    //                 if(typeof aPreFieldos0_i_EventFoncArg==='function'){
                    //         aPreFieldos0_i_EventFoncArg=aPreFieldos0_i_EventFoncArg(this)
                    //                 };

                    //                     for (let eventKey in aPreFieldos0_i_EventFoncArg) {
                    //                       if (eventKey == "load") {
                    //                         aPreFieldos0_i_EventFoncArg[eventKey](this);
                    //                       } else {
                    //                         if (!aPreFieldos0[i].events) { aPreFieldos0[i].events = {} }
                    //                         aPreFieldos0[i]["events"][eventKey] = ($event) =>
                    //                           aPreFieldos0_i_EventFoncArg[eventKey](this, $event);
                    //                       }
                    //                     }
                    //                   }}
                    //           // else  { this.EventFoncArgRecursive(aPreFieldos0[i]) }
                    //         }
                } else return aPreFieldos0;
                // return a
            });

        Vue.prototype.signOut = function () {
            // alert('msg');
            store.commit("updateUser", null);
            store.commit("updateProfile", null);
            store.commit("updateLoggedIn", false);
            // store.commit('updateProfile', {})
            store.commit("updateHeaders", {});
            localStorage.clear();
            // console.log('ffffffffffffffff',router);
            let redirect = "";
            // alert(router.currentRoute.meta.requiresAuth)
            let routerObj = {
                name: "Login",
                // query: {
                //   redirect:redirect
                // }
            };
            if (router.currentRoute.meta.requiresAuth && router.currentRoute.name != "FinishRegistration") {
                routerObj.query = {
                    redirect: router.currentRoute.path,
                };
                router.push(routerObj);
            } else router.go(0);
            // router.push(routerObj);
            // next({ name: 'LogIn', query: { redirect: to.path } })
            // Vue.$cookies.remove('token')
            // Vue.$cookies.remove('currentUsername')
            // this.logOutUsr()
        };
        (Vue.prototype.ObjTransfFunc = function (theSentData, theUrlBackEnd = "default", requestMethod = "post") {
            let aColor = randomColor();

            let theEncriptedSentData = this.aes_encrypt(JSON.stringify(theSentData), this.cryptoKey, this.cryptoKey);

            let theEncriptedSentDataObj = {
                body: theEncriptedSentData,
            };
            let urlServer = this.urlServer + theUrlBackEnd;
            console.log(
                `%c__________Request:${theUrlBackEnd}___(${requestMethod})__________\n`,
                `background-color:${aColor};color:blue;font-size:20px;`,
                "theSentData:",
                theSentData,
                "\n__________\n",
                "Full Data sent to server:",
                { theEncriptedSentDataObj, theUrlBackEnd, requestMethod },
                "\n__________End__________"
            );
            this.console({
                value: [
                    "*********theSentData:*********",
                    theSentData,
                    "\n__________\n",
                    "*********Data sent to server:*********",
                    { theEncriptedSentDataObj, theUrlBackEnd, requestMethod },
                ],

                title: `_____Request:|url:${urlServer}|${requestMethod}_____`,
                propss: { class: "text-h10", color: aColor },
            });
            // var requestMethod = "post";
            // var tmpResult=[];
            // let urlServer = this.urlServer + this.generalData_.header.urlBackEnd,
            if (theUrlBackEnd == "default") {
                theUrlBackEnd = this.generalData_.header.urlBackEnd;
            }

            let queryServerObject = this.$http[requestMethod](urlServer, theEncriptedSentDataObj, this.headers);
            // this.tmpResult=[];
            // console.log(777777777888,queryServerObject);
            // return queryServerObject
            let xx = queryServerObject.then(
                function (response) {
                    response.bodyEnc = response.body;
                    let resp = this.aes_decrypt(response.body, this.cryptoKey, this.cryptoKey);
                    let resp_obj = stringToJson(resp);
                    // this.console('resp_obj',resp_obj)
                    let output = resp_obj[0];
                    // this.tmpResult = output.result;
                    response.body = resp_obj;

                    console.log(
                        `%c__________Response:${theUrlBackEnd}___(${requestMethod})__________\n`,
                        `background-color:${aColor};color:green;font-size:20px;`,
                        "main response data from server",
                        output,
                        "\n__________\n",
                        "full response from server",
                        response,
                        "\n__________End__________"
                    );
                    this.console({
                        value: [
                            "*********main response data from server*********",
                            output,
                            "*********full response from server*********",
                            response,
                        ],
                        title: `_____Response:|url:${response.url}|status:${response.status}|${requestMethod}_____`,
                        propss: { class: "text-h10", color: aColor },
                    });
                    return response;
                    // return output.result;
                },
                (response) => {
                    console.log(`echec ajax`);
                    console.log(
                        `%c__________Failed Response:${theUrlBackEnd}___(${requestMethod})__________\n`,
                        `background-color:${aColor};color:red;font-size:20px;`,
                        "fail response",
                        response,
                        "\n__________End__________"
                    );
                    this.console({
                        value: ["fail response", response],
                        title: `_____Failed Response|url:${response.url}|status:${response.status}|${requestMethod}_____`,
                        propss: { class: "text-h10 red--text", color: aColor },
                    });
                    return response;
                }
            );
            return xx;
        }),
            (Vue.prototype.stringToJson = stringToJson),
            (Vue.prototype.decryptToken = function (Token) {
                let base64Url = Token.split(".")[1];
                let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
                let jsonTokenDetail = decodeURIComponent(
                    Buffer.from(base64, "base64")
                        .toString("ascii")
                        .split("")
                        .map(function (c) {
                            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                        })
                        .join("")
                );

                return JSON.parse(jsonTokenDetail);
            }),
            (Vue.prototype.tokenAccessCheck = function () {
                let accessDecryptedFromStoreStr = this.$Storage.get("access", false),
                    tokenDecryptedFromStore = this.$Storage.get("token", false);
                //         this.$Storage.get('access').then((response)=>{
                // console.log(3333333333333,response);
                //           accessDecryptedFromStoreStr=response
                //         }).then(()=>
                //         {console.log(2222222222222222,accessDecryptedFromStoreStr)
                //         alert(accessDecryptedFromStoreStr)
                //       }
                //         )
                // .then(r=>console.log(11111111111,r));
                // this.$Storage.get('token').then((response)=>{tokenDecryptedFromStore=response});
                // console.log("9999999999999", accessDecryptedFromStoreStr, tokenDecryptedFromStore);
                // let tokenDecryptedFromStore = this.$Storage.get('token');

                if (!accessDecryptedFromStoreStr || !tokenDecryptedFromStore) return;

                let firstCryptoKey = tokenDecryptedFromStore.split(".")[2].slice(0, 16);
                let initialCryptoKey = this.$store.getters.initialCryptoKey;

                let accessDecryptedFromStore = JSON.parse(
                    this.aes_decrypt(accessDecryptedFromStoreStr, initialCryptoKey, initialCryptoKey)
                );

                this.aes_decrypt(this.$Storage.get("token", false), initialCryptoKey, initialCryptoKey);

                if (accessDecryptedFromStore.main.listing.items[0].token == tokenDecryptedFromStore) {
                    this.$store.commit("updateLoggedIn", true);
                    this.$store.commit("updateUser", accessDecryptedFromStore.main.listing.items[0]);

                    this.$store.commit("updateHeaders", tokenDecryptedFromStore);
                    this.$store.commit("updateCryptoKey", tokenDecryptedFromStore);

                    var jwtTokenDetailed = this.decryptToken(tokenDecryptedFromStore);
                    if (jwtTokenDetailed.exp < Date.now() / 1000) {
                        alert("Your session is expired.");
                        this.$store.commit("updateUser", null);
                        this.$store.commit("updateLoggedIn", false);
                        this.$store.commit("updateHeaders", {});
                        localStorage.clear();
                        let redirect = "";
                        let expiredRouterObj = {
                            name: "LoginCompList",
                            // query: {
                            //   redirect:redirect
                            // }
                        };
                        if (this.$router.currentRoute.path != "/") {
                            expiredRouterObj.query = {
                                redirect: this.$router.currentRoute.path,
                            };
                        }
                        this.$router.push(expiredRouterObj);

                        // this.$router.push({
                        //   name: 'LoginCompList',
                        //   query: { redirect: this.$route.path }
                        // })
                    }
                }
            }),
            (Vue.prototype.alert = function (...obj) {
                // let tmp = this.$store.getters.myAlertObjs;
                let theObj = obj.map((h) => {
                    let obbj =
                        h instanceof Object && !Array.isArray(h)
                            ? {
                                  value: [h.value || h],
                                  title: h.title || h,
                              }
                            : {
                                  value: [h],
                                  title: h,
                              };
                    // tmp.push(obbj )
                    return obbj;
                });
                // this.$store.commit('updateMyAlertObjs', tmp)
                this.$modal.show(
                    DialogGeneralModelMyAlertComp,
                    { objs: theObj, console: false },
                    {
                        draggable: true,
                        resizable: true,
                        height: "auto",
                        adaptive: false,
                        width: "80%",
                        shiftX: Math.floor(Math.random() * 10) / 10,
                        shiftY: Math.floor(Math.random() * 10) / 10,
                        scrollable: true,
                        styles: {
                            "background-color": randomColor(),
                            // colorLibelle[
                            //   Math.floor(Math.random() * colorLibelle.length - 1)
                            // ],
                        },
                    }
                );
            }),
            (Vue.prototype.randomColor = randomColor),
            // () => {
            //     var letters = "0123456789ABCDEF";
            //     var color = "#";
            //     for (var i = 0; i < 6; i++) {
            //         color += letters[Math.floor(Math.random() * 16)];
            //     }
            //     return color;
            // }
            (Vue.prototype.randomKey = randomKey),
            (Vue.prototype.compFunc = compFunc),
            (Vue.prototype.strToObj = (str) => {
                try {
                    return JSON.parse(str);
                } catch (error) {
                    return str;
                }
            });

        Vue.prototype.console = function (...obj) {
            // alert("msgffffffffffffffff");
            // console.log(
            //     88888888,
            //     // this,
            //     // store.getters.myAlertObjs,
            //     this.myAlertObjs
            //     // this.$store._vm.myAlertObjs
            // );
            // let tmp = [...{ value: this.myAlertObjs, title: "previous", color: "red" }];
            // obj.push(...this.myAlertObjs);
            let tmp = [];
            // if (this.myAlertObjs) {
            //     console.log(444444444444444, this.myAlertObjs);
            //     tmp = [...this.myAlertObjs];
            // }
            // tmp = [...this.myAlertObjs];

            // console.log(1111111111111, store, store.state.myAlertObjs, this.myAlertObjs);
            // tmp = this.myAlertObjs;
            obj.forEach((h) => {
                tmp.push(
                    h instanceof Object && !Array.isArray(h)
                        ? {
                              value: [h.value || h],
                              title: h.title || h,
                              propss: h?.propss || { class: "white" },
                          }
                        : {
                              value: [h],
                              title: h,
                              propss: { class: "white" },
                          }
                );
            });
            // console.log(55555555555, tmp);
            // alert("this.myAlertObjs" + this.myAlertObjs);
            // alert("tmp" + tmp);
            // console.log(6666666666, [...(this.myAlertObjs || []), ...tmp]);
            // tmp.push({ value: this.myAlertObjs, title: "previous", color: "red" });
            // alert(tmp.length);
            let listToShow = [...tmp];
            if (store.state.myAlertObjs) {
                listToShow = [...store.state.myAlertObjs, ...tmp];
                //     listToShow.push(...this.myAlertObjs);
            }
            store.commit("updateMyAlertObjs", listToShow);
        };

        Vue.prototype.clone = function (data) {
            var copy;

            // Handle the 3 simple types, and null or undefined
            if (null == data || "object" != typeof data) return data;

            // Handle Date
            if (data instanceof Date) {
                copy = new Date();
                copy.setTime(data.getTime());
                return copy;
            }

            // Handle Array
            if (data instanceof Array) {
                copy = [];
                for (var i = 0, len = data.length; i < len; i++) {
                    copy[i] = this.clone(data[i]);
                }
                return copy;
            }

            // Handle Object
            if (data instanceof Object) {
                copy = {};
                for (var attr in data) {
                    if (data.hasOwnProperty(attr)) copy[attr] = this.clone(data[attr]);
                }
                return copy;
            }

            return data;
            // throw new Error("Unable to copy obj! Its type isn't supported.");
        };

        Vue.prototype.generalVal = function (obj, thiss = this) {
            if (
                [
                    "item-text",
                    "itemText",
                    "item-value",
                    "itemValue",
                    "item-to",
                    "itemTo",
                    "val",
                    "value",
                    "comp",
                    "txt",
                    "json",
                    "blob",
                    "text",
                    "events",
                    "EventFoncArg",
                    "load",
                    "click",
                    "headsProps",
                    "td0Props",
                    "trProps",
                    "to",
                ].includes(obj)
            ) {
                return obj;
            } else if (obj instanceof Function) {
                return obj(thiss);
            } else if (Array.isArray(obj)) {
                // let obj_modified=[];
                for (var i = 0; i < obj.length; i++) {
                    obj[i] = thiss.generalVal(obj[i]);
                }
                // for (var o of obj) {
                //   obj_modified.push(thiss.generalVal(o))
                // }
                return obj;
            } else if (typeof obj === "object") {
                for (var o in obj) {
                    // obj[o]=thiss.generalVal(obj[o])
                    if (
                        [
                            "item-text",
                            "itemText",
                            "item-value",
                            "itemValue",
                            "item-to",
                            "itemTo",
                            "val",
                            "txt",
                            "json",
                            "blob",
                            "text",
                            "events",
                            "EventFoncArg",
                            "load",
                            "click",
                            "headsProps",
                            "td0Props",
                            "trProps",
                            "trProps",
                            "to",
                        ].includes(o)
                    ) {
                        continue;
                    }
                    if (typeof obj[o] === "function") {
                        obj[o] = obj[o](thiss);
                    }
                    if (typeof obj[o] === "object") {
                        for (var oo in obj[o]) {
                            if (
                                [
                                    "item-text",
                                    "itemText",
                                    "item-value",
                                    "itemValue",

                                    "item-to",
                                    "itemTo",
                                    "val",
                                    "txt",
                                    "json",
                                    "blob",
                                    "text",
                                    "events",
                                    "EventFoncArg",
                                    "load",
                                    "click",
                                    "headsProps",
                                    "td0Props",
                                    "trProps",
                                    "to",
                                ].includes(oo)
                            ) {
                                continue;
                            }
                            if (typeof obj[o][oo] === "function") {
                                obj[o][oo] = obj[o][oo](thiss);
                            }
                        }
                    }
                }
                return obj;
            }
            return obj;
        };

        //     function generalValue(thiss, obj) {
        //       let thisss = Object.assign({}, thiss);

        //           if (['item-text', 'itemText', 'item-value', 'itemValue', 'val', 'txt', 'json', 'blob', 'text','events','EventFoncArg'].includes(obj)) { return (obj) };
        // // let obj_f;
        //       // console.log('eeeeeeeeeeee',this);
        //       // return;
        //       if (obj instanceof Function)
        //       {
        //        obj=obj(thiss);
        //        // return Object.assign({},Object.assign({},obj))
        //       }
        // // else if(Array.isArray(obj)){
        // // obj=obj.map(e=>generalValue(thiss,e))
        // // }
        //       else if (typeof obj === 'object') {
        //         for (var o in obj) {
        //       //     obj[o]=generalValue(thiss,obj[o])

        //         // let oo = {};
        //           if (['item-text', 'itemText', 'item-value', 'itemValue', 'val', 'txt', 'json', 'blob', 'text','events','rules','EventFoncArg'].includes(o)) { continue; };
        //         // console.log('22222222222',o,obj[o]);
        //           if (typeof obj[o] === 'function') { obj[o] = obj[o](thisss) }
        //         // console.log('3333333333333',o,obj[o]);
        //           if (typeof obj[o] === 'object') {
        //             for (var oo in obj[o]) {
        //               if (['item-text', 'itemText', 'item-value', 'itemValue', 'val', 'txt', 'json', 'blob', 'text','events','rules','EventFoncArg'].includes(oo)) { continue; };
        //               if (typeof obj[o][oo] === 'function') { obj[o][oo] = obj[o][oo](thisss) }
        //             }
        //           }
        //         }
        //         return obj
        //       }
        //   }
    },
};

Vue.use(MyPlugins);

Vue.directive("tag", {
    inserted(el, bind) {
        el.insertAdjacentText("beforeend", bind.value); // Plain text
    },
});

// Vue.directive('purple',(el,h,j)=>{
//   el.style.color='purple';
// })
Vue.directive("color", (el, h, j) => {
    el.style.color = h.value;
});

// import VueCrypting from '@/crypto/MyCrypto.js'
// console.log('bbbbbbbbbb', VueCrypting);
// Vue.prototype.$VueCrypting = VueCrypting

////////////////The Vue app///////////////////

let v = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
});
v.$mount("#app");
global.v = v;
global.vuetifyModule = vuetifyModule;
//////////////////////////////////////
//function that converts to title case
// but also preserves defined acronyms as uppercase
//and minor words as lowercase:
//////////////////////////////////////

/**
 * OBJECT GET
 * Used to get an object property securely
 * @param object
 * @param property
 * @returns {*}
 */

Object.get = function (object, property, alternative = null) {
    var properties = property.split(".");
    for (var i = 0; i < properties.length; i++) {
        if (object && object[properties[i]]) {
            object = object[properties[i]];
        } else {
            return alternative;
        }
    }
    return object;
};

String.prototype.isDate = function () {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (this.match(regex) === null) {
        return false;
    }

    const date = new Date(this);

    const timestamp = date.getTime();

    if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
    }

    return date.toISOString().startsWith(this);
};
String.prototype.toJSON = function () {
    try {
        return JSON.parse(this);
    } catch (error) {
        return undefined;
    }
};
String.prototype.isJSON = function () {
    try {
        JSON.parse(JSON.stringify(eval(this)));
        return true;
    } catch (error) {
        return false;
    }
};

String.prototype.toTitleCase = function () {
    var i, j, str, lowers, uppers;
    str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    // Certain minor words should be left lowercase unless
    // they are the first or last words in the string
    lowers = [
        "A",
        "An",
        "The",
        "And",
        "But",
        "Or",
        "For",
        "Nor",
        "As",
        "At",
        "By",
        "For",
        "From",
        "In",
        "Into",
        "Near",
        "Of",
        "On",
        "Onto",
        "To",
        "With",
    ];
    for (i = 0, j = lowers.length; i < j; i++)
        str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
            return txt.toLowerCase();
        });

    // Certain words such as initialisms or acronyms should be left uppercase
    uppers = ["Id", "Tv"];
    for (i = 0, j = uppers.length; i < j; i++)
        str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

    return str;
};

Function.prototype.range = (a = 0, b = 0, c = 1) =>
    Array.from(Array(Math.abs(b - a)), (_, i) => {
        if (typeof a != "number" || typeof b != "number" || typeof c != "number") {
            return null;
        }
        if (b == 0 && a > 0) {
            [a, b] = [b, a];
            c = 1;
        }
        let l = a + i * c;
        if (l < b) {
            return l;
        } else {
            return null;
        }
    }).filter((h) => h != null);

// Function.prototype.annotate = function() {
//     let FN_ARGS = /^function\s*[^\(]*\(\s*([^\)]*)\)/m;
//     let FN_ARG_SPLIT = /,/;
//     let FN_ARG = /^\s*(_?)(.+?)\1\s*$/;
//     let STRIP_COMMENTS = /(\/\/.*$)|(\/\*[\s\S]*?\*\/)|(\s*=[^,\)]*(('(?:\\'|[^'\r\n])*')|("(?:\\"|[^"\r\n])*"))|(\s*=[^,\)]*))/mg;

//     let $inject,
//       fnText,
//       argDecl,
//       last;

//     if (typeof this == 'function') {
//       if (!($inject = this.$inject)) {
//         $inject = [];
//         fnText = this.toString().replace(STRIP_COMMENTS, '');
//         argDecl = fnText.match(FN_ARGS);
//         argDecl[1].split(FN_ARG_SPLIT).forEach(function(arg) {
//           arg.replace(FN_ARG, function(all, underscore, name) {
//             $inject.push(name);
//           });
//         });
//         this.$inject = $inject;
//       }
//     } else {
//       throw Error("not a function")
//     }
//     return $inject;
//   },

// console.log('4/////////////////////////',moment('2021-09-01').day(1)._i);
// Date.prototype.fromItsMonday = function(da = 7) {
//   let dateStr = this.toISOString().slice(0, 10);
//   // var day = date.getDay() || 7;
//   // if (day !== 1)
//   //   date.setHours(-24 * (day - 1));

//   // if (day !== 1)
//   //   date.setHours(-24 * (day - 1));

//   // var monday = date;
//   let dd = moment(dateStr).startOf("week").format();
//   // alert(typeof dd+dd);
//   var monday = new Date(dd);

//   let days = [];
//   // [1, 1, 1, 1].map(h => { theDay.setDate(theDay.getDate() + 1); return theDay.toLocaleString(obj['lg'], { weekday: 'long' }) + ' ' + theDay.toISOString().slice(0, 10) })
//   for (let i = 0; i < da; i++) {
//     // monday.setDate(monday.getDate() + 1)
//     // days.push(new Date(this.setDate(diff+i)));
//     days.push(new Date(monday.setDate(monday.getDate() + 1)));
//     // alert(days);
//   }
//   return days;
// }

// String.prototype.depthOf = function() {
//           return 0
// };
// Boolean.prototype.depthOf = function() {
//           return 0
// };
// Function.prototype.depthOf = function(obj) {
//   if (!Array.isArray(obj) && (obj instanceof Object)) {
//     var level = 1;
//     for (var key in obj) {
//       if (!obj.hasOwnProperty(key)) continue;

//       if (typeof obj[key] == 'object') {
//         var depth = depthOf(obj[key]) + 1;
//         level = Math.max(depth, level);
//       }
//     }
//     return level;
//   } else if (Array.isArray(obj)) return 0
//   else return -1
// }

// let d = new Date("2021-11-22 00:00:00");
// alert(d)
// alert(d.fromItsMonday(7));
// alert(d.fromItsMonday(7).map(h => h.toISOString().slice(0, 10)));

// let x = function(a, b) {
//   console.log(a, b, c, d)
// };
// alert(x.annotate())

// export  const { Vue0 } = Vue
