import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// let u = store.state.user;

////////////////////////////////////////////
// import TheComp from "@/components/theComps/theItemsTableComp/TheTheComp.vue";
// import ItemsTableComp from "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue";
///////////////////////////////////////////
// var isLoggedIn = store.getters.loggedIn

Vue.use(VueRouter);
const routes = [
    /////////////////////////////////// chrome extension ////////////////////////////////////////////////////////
    {
        path: "/popup",
        name: "popup",
        component: () => import("../entry/popup/app/PopupApp.vue"),
    },
    {
        path: "/options",
        name: "options",
        component: () => import("../entry/options/app/OptionsApp.vue"),
    },
    {
        path: "/devtools",
        name: "devtools",
        component: () => import("../entry/devtools/app/DevtoolsApp.vue"),
    },
    ////////////////////////// ui general pages ////////////////////////////////
    {
        path: "/",
        name: "HomeView",
        component: () => import("../views/ui/home/HomeView.vue"),
        // props:false
    },
    {
        path: "/help",
        name: "HelpView",
        // component: HelpView
        component: () => import(/* webpackChunkName:"HelpView" */ "@/views/ui/help/HelpView.vue"),
        meta: {
            requiresAuth: !true,
        },
    },

    /////////////////////////////////// ui/account ////////////////////////////////////////////////////////
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/ui/account/login/Login.vue"),
        query: {},
        meta: {
            requiresAuth: false,
        },
        //         beforeEnter(to, from, next) {
        //   // console.log('rrrrrrrrr', to, from, u)

        //               if(u)
        //     {
        //       alert('ssssssssss')
        //   // alert(store.state.user.email);

        //       // next({ name: 'Home' });
        //   }
        //     next()
        // }
    },
    {
        path: "/logout",
        name: "LogOut",
        component: () => import("../views/ui/account/logout/LogOut.vue"),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/ui/account/register/Register.vue"),
        meta: {
            requiresAuth: false,
            // permissions: ['isSuperuser', 'isStaff', 'isActive'],
            // groupes: "management"
        },
    },
    {
        path: "/finish",
        name: "FinishRegistration",
        component: () => import("../views/ui/account/finishRegistration/FinishRegistration.vue"),

        meta: {
            requiresAuth: true,
            // permissions: ['isSuperuser', 'isStaff', 'isActive'],
            // groupes: "management"
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/ui/account/profile/Profile.vue"),
        meta: {
            requiresAuth: true,
            // permissions: ['isSuperuser', 'isStaff', 'isActive'],
            // groupes: "management"
        },
    },
    {
        path: "/account/activate/:uidb64/:token",
        name: "ActivateUser",
        component: () => import("../views/settings/ActivateUser.vue"),
        // props: true,
    },
    /////////////////////////////////// ui/timetable ////////////////////////////////////////////////////////
    {
        // http://127.0.0.1:8080/timetable/Tennis%20Club%20of%20Monastir/date
        path: "/timetable/:club/:date",
        name: "GeneralTimeTableUi",
        component: () => import("../views/ui/timetable/daily/general/GeneralTimeTableUi.vue"),

        // props: true,
        meta: {
            requiresAuth: !true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
        beforeEnter(to, from, next) {
            // alert(JSON.stringify(u));
            // alert('beforeEnter(to,from,next), Check the console for more details.')
            // console.log('ddddddddddddddddd', 'to', to);
            // console.log('from', from);
            // console.log('next', next);
            // console.log('store', store);
            // console.log('dateIsValid', dateIsValid(to.params.date));
            if (to.params.date !== "date" && !dateIsValid(to.params.date)) {
                next({ name: "NotFound" });
                next({ name: "NotFound", query: { redirect: to.path } });
            }
            next();
        },
    },
    {
        ///////////////////////////  the range from 2022-05-24 to 2022-06-04//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/2022-06-04/adnen/bondi/compact/training/ALL

        ///////////////////////////  the range from today to 2022-06-24//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/2022-06-24/adnen/bondi/compact/training/ALL

        ///////////////////////////  the day 2022-05-24//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/day/adnen/bondi/compact/training/ALL

        /////////////////////////// the current day//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/day/adnen/bondi/compact/training/ALL

        /////////////////////////// the week of the day 2022-05-24//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/week/adnen/bondi/compact/training/ALL

        /////////////////////////// the current week//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/week/adnen/bondi/compact/training/ALL

        /////////////////////////// the month of the day 2022-05-24//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/month/adnen/bondi/compact/training/ALL

        /////////////////////////// the current month//////////////////////
        //http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/month/adnen/bondi/compact/training/ALL

        /////////////////////////// the current month//////////////////////
        // http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-23/2022-06-02/coach/coach/compact/private,TRAINING/all

        path: "/timetable/coach/:club/:year/:club/:from/:to/:first_name/:last_name/:type_session/:type_display/:state",
        name: "CoachTimeTableUi",
        component: () => import("../views/ui/timetable/weekly/coach/CoachTimeTableUi.vue"),
        // props: true,
        meta: {
            requiresAuth: !true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
        //     beforeEnter(to, from, next) {

        //   // alert('beforeEnter(to,from,next), Check the console for more details.')
        //   // console.log('ddddddddddddddddd', 'to', to);
        //   // console.log('from', from);
        //   // console.log('next', next);
        //   // console.log('store', store);
        //   // console.log('dateIsValid', dateIsValid(to.params.date));
        //   if (to.params.date!=='date' && !dateIsValid(to.params.date))
        //    {
        //     alert('msg');
        //     next({ name: 'NotFound'});
        //   next({ name: 'NotFound', query: { redirect: to.path } });

        //   }
        //   next()
        // }
    },
    {
        ///////////////////////////  the range from 2022-05-24 to 2022-06-04//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-05-24/2022-06-04/ab/training/compact/planned

        ///////////////////////////   today to 2022-08-10//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/blabla/today/ab/training/compact/planned
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/todayblabla//ab/training/compact/planned

        ///////////////////////////  the day 2022-08-10//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-08-10/day/ab/training/compact/planned
        ////or as a range from 2022-08-10 to 2022-08-10////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-08-10/2022-08-10/ab/training/compact/planned

        /////////////////////////// the current day//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/today/day/ab/training/compact/planned

        /////////////////////////// the week of the day 2022-05-24//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-05-24/week/ab/training/compact/planned

        /////////////////////////// the current week//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/today/week/ab/training/compact/planned

        /////////////////////////// the month of the day 2022-07-09//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-07-09/month/ab/training/compact/planned

        /////////////////////////// the current month//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/today/month/ab/training/compact/planned
        /////////////////////////// sae for fitness or multi fitness or training//////////////////////
        // http://127.0.0.1:8080/timetable/group/tennis%20club%20of%20monastir/2022/liga%20tennis/today/month/ab/training,fitness/compact/planned
        //
        path: "/timetable/group/:club/:year/:season/:from/:to/:group/:type_session/:type_display/:state",
        name: "GroupTimeTableUi",
        component: () => import("../views/ui/timetable/weekly/group/GroupTimeTableUi.vue"),
        // props: true,
        meta: {
            requiresAuth: !true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
        //     beforeEnter(to, from, next) {

        //   // alert('beforeEnter(to,from,next), Check the console for more details.')
        //   // console.log('ddddddddddddddddd', 'to', to);
        //   // console.log('from', from);
        //   // console.log('next', next);
        //   // console.log('store', store);
        //   // console.log('dateIsValid', dateIsValid(to.params.date));
        //   if (to.params.date!=='date' && !dateIsValid(to.params.date))
        //    {
        //     alert('msg');
        //     next({ name: 'NotFound'});
        //   next({ name: 'NotFound', query: { redirect: to.path } });

        //   }
        //   next()
        // }
    },
    {
        ///////////////////////////  the range from 2022-05-24 to 2022-06-04//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-05-24/2022-06-04/ab/aya/boussaid/training/compact/planned

        ///////////////////////////   today to 2022-08-10//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/blabla/today/ab/aya/boussaid/training/compact/planned
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/todayblabla//ab/aya/boussaid/training/compact/planned

        ///////////////////////////  the day 2022-08-10//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-08-10/day/ab/aya/boussaid/training/compact/planned
        ////or as a range from 2022-08-10 to 2022-08-10////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-08-10/2022-08-10/ab/aya/boussaid/training/compact/planned

        /////////////////////////// the current day//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/today/day/ab/aya/boussaid/training/compact/planned

        /////////////////////////// the week of the day 2022-05-24//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-05-24/week/ab/aya/boussaid/training/compact/planned

        /////////////////////////// the current week//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/today/week/ab/aya/boussaid/training/compact/planned

        /////////////////////////// the month of the day 2022-07-09//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/2022-07-09/month/ab/aya/boussaid/training/compact/planned

        /////////////////////////// the current month//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/today/month/ab/aya/boussaid/training/compact/planned
        /////////////////////////// sae for fitness or multi fitness or training//////////////////////
        // http://127.0.0.1:8080/timetable/player/tennis%20club%20of%20monastir/2022/liga%20tennis/today/month/ab/aya/boussaid/training,fitness/compact/planned
        path: "/timetable/player/:club/:year/:season/:from/:to/:group/:first_name/:last_name/:type_session/:type_display/:state",
        name: "PlayerTimeTableUi",
        component: () => import("../views/ui/timetable/weekly/player/PlayerTimeTableUi.vue"),
        // props: true,
        meta: {
            requiresAuth: !true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
        //     beforeEnter(to, from, next) {

        //   // alert('beforeEnter(to,from,next), Check the console for more details.')
        //   // console.log('ddddddddddddddddd', 'to', to);
        //   // console.log('from', from);
        //   // console.log('next', next);
        //   // console.log('store', store);
        //   // console.log('dateIsValid', dateIsValid(to.params.date));
        //   if (to.params.date!=='date' && !dateIsValid(to.params.date))
        //    {
        //     alert('msg');
        //     next({ name: 'NotFound'});
        //   next({ name: 'NotFound', query: { redirect: to.path } });

        //   }
        //   next()
        // }
    },
    ////////////////////////// nested components ////////////////////////////////
    {
        path: "/about",
        name: "AboutView",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName:"about" */ "../views/ui/about/AboutView.vue"),
        // props:{b:20},
        children: [
            {
                path: "theitemstable0",
                name: "RoutesToTheItemsTableComp",
                // regexp:/^\/itemstable\/?$/i,
                // component: ItemsTableComp,
                component: () =>
                    import(
                        /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue"
                    ),
                props: {
                    outputData: {
                        items: [
                            { x: 10, y: 100, z: 1000, t: "-" },
                            { x: 20, y: 200, z: 2000 },
                            { x: 30, y: 300, z: 3000 },
                            { x: 40, y: 400, z: 4000, t: 5000 },
                        ],
                    },
                },
            },

            {
                path: "theitemstable1",
                name: "RoutesToTheItemsTableComp",
                // regexp:/^\/itemstable\/?$/i,
                // component: ItemsTableComp,
                component: () =>
                    import(
                        /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue"
                    ),
                props: {
                    outputData: {
                        items: [
                            { x: 50, y: 500, z: 5000, t: "-" },
                            { x: 20, y: 200, z: 2000 },
                            { x: 30, y: 300, z: 3000 },
                            { x: 40, y: 400, z: 4000, t: 5000 },
                        ],
                    },
                },
            },
        ],
        meta: {
            requiresAuth: !true,
        },
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// ViewModel reference///////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        //http://127.0.0.1:8080/viewModel0
        path: "/viewModel0",
        name: "ViewModel",
        component: () => import("../views/ui/viewModel/ViewModel.vue"),
        query: {},
        meta: {
            requiresAuth: false,
        },
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// thecomps Model reference///////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // ##########################ItemsTableComp##################################
    {
        //http://127.0.0.1:8080/theitemstablecomp0
        path: "/theitemstablecomp0",
        name: "TheItemsTableComp0",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue"
            ),
    },
    {
        //http://127.0.0.1:8080/theitemstablecomp1
        path: "/theitemstablecomp1",
        name: "TheItemsTableComp1",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue"
            ),
        props: {
            outputData: {
                items: [
                    { x: 10, y: 100, z: 1000, t: "-" },
                    { x: 20, y: 200, z: 2000 },
                    { x: 30, y: 300, z: 3000 },
                    { x: 40, y: 400, z: 4000, t: 5000 },
                ],
            },
        },
    },
    {
        //http://127.0.0.1:8080/theitemstablecomp2
        path: "/theitemstablecomp2",
        name: "TheItemsTableComp2",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue"
            ),
        props: {
            outputData: {
                items: [
                    {
                        x: "[[50|green|http://www.google.com]]",
                        y: { comp: "VCard", props: { html: "bla", class: "yellow" } },
                        z: 1000,
                        t: "-",
                    },
                    { x: 20, y: 200, z: 2000 },
                    { x: 30, y: 300, z: 3000 },
                    { x: 40, y: 400, z: 4000, t: 5000 },
                ],
            },
        },
    },
    {
        //http://127.0.0.1:8080/theitemstablecomp3
        path: "/theitemstablecomp3",
        name: "TheItemsTableComp3",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue"
            ),
        props: {
            outputData: {
                items: [
                    {
                        x: "[[50|green|http://www.google.com]]",
                        y: { comp: "VCard", props: { html: "bla", class: "yellow" } },
                        z: 1000,
                        t: "-",
                    },
                    { x: 20, y: 200, z: 2000 },
                    { x: 30, y: 300, z: 3000 },
                    { x: 40, y: 400, z: 4000, t: 5000 },
                ],
                headers: [
                    {
                        text: "X",
                        value: "XXXXX",
                        comp: "VCard",
                        props: {
                            hide: false,
                            key: "key-x",
                            html: "x",
                            class: "red darken-1  text-center text-h4 blue--text text-darken-2",
                        },
                    },
                    {
                        text: "Y",
                        value: "YYYYY",
                        comp: "VCard",
                        props: {
                            hide: false,
                            key: "key-x",
                            html: "y",
                            class: "red darken-1  text-center text-h4 blue--text text-darken-2",
                        },
                    },
                    {
                        comp: "VCard",
                        props: {
                            hide: false,
                            key: "key-x",
                            html: "z",
                            class: "red darken-1  text-center text-h4 blue--text text-darken-2",
                        },
                    },
                    {
                        comp: "VCard",
                        props: {
                            hide: false,
                            key: "key-x",
                            html: "t",
                            class: "red darken-1  text-center text-h4 blue--text text-darken-2",
                        },
                    },
                ],
            },
        },
    },

    // ##########################TheItemsSectionsComp##################################
    {
        //
        path: "/theItemsSectionsComp0",
        name: "TheItemsSectionsComp0",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsSectionsComp/TheItemsSectionsComp.vue"
            ),
    },
    // ##########################ItemsTranspTableComp##################################
    {
        //http://127.0.0.1:8080/itemstransptablecomp0
        path: "/itemstransptablecomp0",
        name: "ItemsTranspTableComp0",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTranspTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTranspTableComp.vue"
            ),
    },
    {
        //http://127.0.0.1:8080/itemstransptablecomp1
        path: "/itemstransptablecomp1",
        name: "ItemsTranspTableComp1",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTranspTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTranspTableComp.vue"
            ),
        props: {
            outputData: {
                items: [
                    { x: 10, y: 100, z: 1000, t: "-" },
                    { x: 20, y: 200, z: 2000 },
                    { x: 30, y: 300, z: 3000 },
                    { x: 40, y: 400, z: 4000, t: 5000 },
                ],
            },
        },
    },
    {
        //http://127.0.0.1:8080/itemstransptablecomp2
        path: "/itemstransptablecomp2",
        name: "ItemsTranspTableComp2",
        component: () =>
            import(
                /* webpackChunkName:"ItemsTranspTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTranspTableComp.vue"
            ),
        // component:ItemsTranspTableComp,
        props: {
            outputData: {
                items: [
                    {
                        x: "[[50|green|http://www.google.com]]",
                        y: { comp: "VCard", props: { html: "bla", class: "yellow" } },
                        z: 1000,
                        t: "-",
                    },
                    { x: 20, y: 200, z: 2000 },
                    { x: 30, y: 300, z: 3000 },
                    { x: 40, y: 400, z: 4000, t: 5000 },
                ],
            },
        },

        // ##########################ItemsTranspTableComp##################################
    },

    {
        path: "/thecomp",
        name: "TheComp",
        component: () => import(/* webpackChunkName:"TheComp" */ "../components/theComps/theCompComp/TheComp.vue"),
        // component: TheComp,
        props: {
            item: "[[Routing to a componnent not to a view|text-h3 font-weight-bold]]",
        },
    },

    {
        path: "/wbc",
        name: "WikiWBC",
        component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/wiki/wikiWBC/WikiWBC.vue"),
        // component: TheComp,
        // props: {
        //   item: '[[Routing to a componnent not to a view|text-h3 font-weight-bold]]',
        // },
    },

    ///////////////////////////////////To sort///////////////////////////////////////////////////////
    {
        path: "/itemstable",
        name: "RoutesToTheItemsTableComp",
        // regexp:/^\/itemstable\/?$/i,
        // component: ItemsTableComp,
        component: () =>
            import(
                /* webpackChunkName:"ItemsTableComp" */ "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue"
            ),
        props: {
            outputData: {
                items: [
                    { x: 10, y: 100, z: 1000, t: "-" },
                    { x: 20, y: 200, z: 2000 },
                    { x: 30, y: 300, z: 3000 },
                    { x: 40, y: 400, z: 4000, t: 5000 },
                ],
            },
        },
    },

    {
        path: "/rbfe",
        name: "RoutesBeforeEnter",

        beforeEnter(to, from, next) {
            alert("beforeEnter(to,from,next), Check the console for more details.");
            console.log("ddddddddddddddddd", "to", to);
            console.log("from", from);
            console.log("next", next);
            next({ name: "NotFound", query: { redirect: "4jjjjj" }, hash: "#vdvsds" });
        },
    },

    // {
    //   path: "/profile0",
    //   name: "Profile0",
    //   component: Profile0,
    //   meta: {
    //     requiresAuth: false,
    //     // permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //     // groupes: "management"
    //   }
    // },
    {
        path: "/managecs",
        name: "AdminPanelCompList",
        component: () => import("../views/adminPanel/AdminPanelCompList.vue"),
        meta: {
            requiresAuth: false,
            // permissions: ['isSuperuser', 'isStaff', 'isActive'],
            // groupes: "management"
        },
    },

    {
        path: "/ui/public/account/:user",
        name: "UserPublicDetails",
        component: () => import("../views/detailsPages/user/UserPublicDetails.vue"),
        props: true,
    },
    {
        path: "/email/:email",
        name: "EmailDetails",
        component: () => import("../views/ui/account/emailDetails/EmailDetails.vue"),

        props: true,
    },
    // {
    //     path: "/status/:status",
    //     name: "StatusDetails",
    //     component: StatusDetails,
    //     props: true
    //   },
    // {
    //     path: "/court/:court",
    //     name: "CourtDetails",
    //     component: CourtDetails,
    //     props: true
    //   },

    // {
    //    path: "/groupp/:groupp",
    //    name: "GrouppDetails",
    //    component: GrouppDetails,
    //    props: true
    //  },
    // {
    //    path: "/playground/:playground",
    //    name: "PlaygroundDetails",
    //    component: PlaygroundDetails,
    //    props: true
    //  },
    // {
    //     path: "/staff/:staff",
    //     name: "StaffDetails",
    //     component: StaffDetails,
    //     props: true
    //   },
    {
        //http://127.0.0.1:8080/Tennis%20Club%20of%20Monastir/page/Liga%20Tennis/training/2021-09-16/Court%201/10:00/12:00
        path: "/:groupe__season__club__name__iexact/page/:groupe__season__name__iexact/training/:session__date__day/:session__playground__name__iexact/:session__times__begin/:session__times__end",
        name: "TrainingDetails",
        component: () => import("../views/detailsPages/training/training/TrainingDetails.vue"),

        props: true,
    },
    {
        path: "/:groupe__season__club__name__iexact/page0/:groupe__season__name__iexact/training/:session__date__day/:session__playground__name__iexact/:session__times__begin/:session__times__end",
        name: "TrainingDetails0",
        component: () => import("../views/detailsPages/training/training/TrainingDetails0.vue"),
        props: true,
    },
    {
        path: "/:season__club__name__iexact/page/:season__name__iexact/private/:session__date__day/:session__playground__name__iexact/:session__times__begin/:session__times__end/:coach__first_name__iexact/:coach__last_name__iexact/:player__first_name__iexact/:player__last_name__iexact",
        name: "PrivateDetails",
        component: () => import("../views/detailsPages/training/private/PrivateDetails.vue"),

        props: true,
    },
    {
        path: "/:season__club__name__iexact/page/:season__name__iexact/booking/:session__date__day/:session__playground__name__iexact/:session__times__begin/:session__times__end/:player__first_name__iexact/:player__last_name__iexact",
        name: "BookingDetails",
        component: () => import("../views/detailsPages/training/booking/BookingDetails.vue"),

        props: true,
    },
    {
        path: "/:season__club__name__iexact/page/rental/:session__date__day/:session__playground__name__iexact/:session__times__begin/:session__times__end/:user__username",
        name: "RentalDetails",
        component: () => import("../views/detailsPages/training/rental/RentalDetails.vue"),

        props: true,
    },
    {
        path: "/:groupe__season__club__name__iexact/page/:groupe__season__name__iexact/fitness/:session__date__day/:session__playground__name__iexact/:session__times__begin/:session__times__end",

        name: "FitnessDetails",
        component: () => import("../views/detailsPages/training/fitness/FitnessDetails.vue"),

        props: true,
    },
    // {
    //     path: "/:training__groupe__season__club__name__iexact/:training__groupe__season__name__iexact/session/:date__day/:times__begin/:times__end/:playground__name",
    //     // /session/2021-09-16/08:00-10:00/court 1
    //     name: "SessionDetails",
    //     component: SessionDetails,
    //     props: true
    //   },

    {
        // http://127.0.0.1:8080/detail/player/tennis%20club%20of%20monastir/2022/liga%20tennis/ab/aya/boussaid
        // path: "/:groupe__season__club__name__iexact/page/:groupe__season__name__iexact/player/:first_name__iexact/:last_name__iexact",
        path: "/detail/player/:club__name/:season__year/:season__name/:group__name/:player__first_name/:player__last_name",

        name: "PlayerDetails",
        component: () => import("../views/detailsPages/player/player/PlayerDetails.vue"),

        props: !true,
        meta: {
            requiresAuth: !true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/detail/categoryplayer/:club__name/:year/:season__name/:categoryplayer_name",
        name: "CategoryPlayerDetails",
        component: () => import("../views/detailsPages/player/categoryPlayer/CategoryPlayerDetails.vue"),

        props: true,
    },
    {
        path: "/detail/membership/:club__name/:year/:season__name/:first_name/:last_name",
        name: "MembershipDetails",
        component: () => import("../views/detailsPages/membership/MembershipDetails.vue"),

        props: true,
    },
    // {
    //     path: "/typeplayer/:typeplayer",
    //     name: "TypePlayerDetails",
    //     component: TypePlayerDetails,
    //     props: true
    //   },
    {
        path: "/:club__name/page/:season__year/:season__name/coach/:first_name/:last_name",
        name: "CoachDetails",
        component: () => import("../views/detailsPages/coach/CoachDetails.vue"),

        props: true,
    },
    {
        path: "/contract/:contract",
        name: "ContractDetails",
        component: () => import("../views/contract/ContractDetails.vue"),

        props: true,
    },

    {
        path: "/:club__name__iexact/season/:year/:name__iexact",
        name: "SeasonDetails",
        component: () => import("../views/detailsPages/season/SeasonDetails.vue"),

        props: true,
    },
    {
        path: "/club/:name__iexact",
        name: "ClubDetails",
        component: () => import("../views/detailsPages/club/ClubDetails.vue"),

        props: true,
    },

    {
        path: "/groupe/detail/:club__name/:season__year/:season__name/:groupe__name",
        // ex:http://127.0.0.1:8080/seasons/ui/group/adult/podium/eeeee
        name: "GroupDetails",
        component: () => import("../views/detailsPages/group/group/GroupDetails.vue"),

        props: true,
    },

    {
        path: "/detail/category/:club__name/:season__year/:season__name/:category__name",
        // ex:http://127.0.0.1:8080/seasons/ui/category/Tennis%20League/adult
        name: "CategoryDetails",
        component: () => import("../views/detailsPages/group/category/CategoryDetails.vue"),

        props: true,
    },

    {
        path: "/detail/typegroupe/:club__name/:season__year/:season__name/:type_groupe__name",
        // ex:http://127.0.0.1:8080/seasons/ui/typegroup/Tennis%20League/Rtrt
        name: "TypeGroupDetails",
        component: () => import("../views/detailsPages/group/typeGroup/TypeGroupDetails.vue"),

        props: true,
    },

    // {
    // http://127.0.0.1:8080/timetable/Tennis%20Club%20of%20Monastir/date
    //   path: "/timetable/:club/date/:date",
    //   name: "GeneralTimeTableUi",
    //   component: GeneralTimeTableUi,
    //   props: true
    // },
    //   {
    //     // http://127.0.0.1:8080/timetable/Tennis%20Club%20of%20Monastir/Liga%20Tennis/2021-09-16/all/90/1
    //     path: "/timetable/:club/:season/:date/group/:group",
    //     name: "GroupTimeTableCompList",
    // component:()=>import ("../views/ui/timetable/general/group/GroupTimeTableCompList.vue"),

    //     props: true
    //   },
    //   {
    //     // http://127.0.0.1:8080/timetable/Tennis%20Club%20of%20Monastir/Liga%20Tennis/2021-09-16/all/90/1
    //     path: "/timetable/:club/:season/:date/coach/:group",
    //     name: "CoachTimeTableCompList",
    // component:()=>import ("../views/ui/timetable/general/coach/CoachTimeTableCompList.vue"),

    //     props: true
    //   },
    {
        path: "/mst",
        name: "ModelSimpleTable",
        component: () => import("../views/modelsViews/modelSimpleTable/ModelSimpleTable.vue"),
        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },

    {
        path: "/models/mgsch",
        name: "ModelGeneralScheduleBrut",
        component: () => import("../views/modelsViews/modelSchedule/ModelGeneralScheduleBrut.vue"),
        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },
    {
        path: "/models/msch",
        name: "ModelScheduleBrut",
        component: () => import("../views/modelsViews/modelSchedule/ModelScheduleBrut.vue"),
        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },
    {
        path: "/mst0",
        name: "ModelSimpleTable0",
        component: () => import("../views/modelsViews/modelSimpleTable/ModelSimpleTable0.vue"),
        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },
    // {
    //   path: "/mtt",
    //   name: "ModelTranspTable",
    //   component: ModelTranspTable,
    //   meta: {
    //     requiresAuth: true,
    //     permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //     groupes: "management"
    //   }
    // },
    {
        path: "/modelcomplist",
        name: "ModelCompList",
        component: () => import("../views/modelsViews/generalCompSetComp/ModelCompList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/modelmyalert",
        name: "GeneralModelMyAlert",
        component: () => import("../views/modelsViews/modelMyAlert/GeneralModelMyAlert.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/modelgeneralobject",
        name: "ModelGeneralObject",
        component: () => import("../views/modelsViews/modelGeneralObject/ModelGeneralObject.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/generalmodelobjs",
        name: "GeneralModelObjs",
        component: () => import("../views/modelsViews/generalModelObjs/groups/GeneralModelObjs.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },

    // {
    //   path: "/mgottb",
    //   name: "ModelGeneralObjectTranspTableBrut",
    //   component: ModelGeneralObjectTranspTableBrut,
    //   meta: {
    //     requiresAuth: true,
    //     permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //     groupes: "management"
    //   }
    // },
    {
        path: "/mgob",
        name: "ModelGeneralObjectBrut",
        component: () => import("../views/modelsViews/modelGeneralObject/ModelGeneralObjectBrut.vue"),
        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },
    {
        path: "/gpcl",
        name: "GeneralPageCompList",
        component: () => import("../views/modelsViews/generalPage/GeneralPageCompList.vue"),
        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },
    {
        path: "/gpcs",
        name: "GeneralPageCompSet",
        component: () => import("../views/modelsViews/generalPage/GeneralPageCompSet.vue"),
        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },
    {
        path: "/presword",
        name: "PresWord",
        component: () => import("../views/ui/theClub/presWord/PresWord.vue"),

        // meta: {
        //   requiresAuth: true,
        //   permissions: ['isSuperuser', 'isStaff', 'isActive'],
        //   groupes: "management"
        // }
    },

    // {
    //     path: "/ccl",
    //     name: "ClubCompList",
    //     component: ClubCompList,
    //     meta: {
    //       requiresAuth: true,
    //       permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //       groupes: "management"
    //     }
    //   },
    {
        path: "/equipments",
        name: "Equipments",
        component: () => import("../views/ui/theClub/equipments/Equipments.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    // {
    //   path: "/staff",
    //   name: "Staff",
    //   component: Staff,
    //   meta: {
    //     requiresAuth: true,
    //     permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //     groupes: "management"
    //   }
    // },
    // {
    //   path: "/groups",
    //   name: "Groups",
    //   component: Groups,
    //   meta: {
    //     requiresAuth: true,
    //     permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //     groupes: "management"
    //   }
    // },
    /////Management//////
    {
        path: "/management/seasons",
        name: "SeasonCompList",
        component: () => import("../views/management/season/SeasonCompList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/playgrounds",
        name: "PlaygroundCompList",
        component: () => import("../views/management/playground/PlaygroundCompList.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/coaches",
        name: "CoachCompList",
        component: () => import("../views/management/coach/CoachCompList.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/staff",
        name: () => import("../views/management/staff/StaffCompList.vue"),
        component: () => import("../views/management/staff/StaffCompList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/Memberships/club",
        name: "MembershipCompList",
        component: () => import("../views/management/membership/MembershipCompList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/players",
        name: "PlayerCompList",
        component: () => import("../views/management/player/PlayerCompList.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/sessiondate",
        name: "SessionDateCompList",
        component: () => import("../views/management/session/sessionDate/SessionDateCompList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/sessionschedules",
        name: "SessionScheduleCompList",
        component: () => import("../views/management/session/sessionSchedule/SessionScheduleCompList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/session",
        name: "SessionCompList",
        component: () => import("../views/management/session/SessionCompList.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/categories",
        name: "CategoryCompList",
        component: () => import("../views/management/groupe/category/CategoryCompList.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/typegroupes",
        name: "TypeGroupeCompList",
        component: () => import("../views/management/groupe/typegroupe/TypeGroupeCompList.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/groupes",
        name: "GroupeCompList",
        component: () => import("../views/management/groupe/GroupeCompList.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/activity/trainings",
        name: "TrainingAllBComp",
        component: () => import("../views/management/activity/training/TrainingAllBComp.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/individuals",
        name: "IndividualsView",
        component: () => import("../views/management/individuals/IndividualsView.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/bookings",
        name: "BookingsView",
        component: () => import("../views/management/bookings/BookingsView.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/management/rentals",
        name: "RentalsView",
        component: () => import("../views/management/rentals/RentalsView.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    // {
    //   path: "/modelgeneraltablejson",
    //   name: "modelgeneraltablejson",
    //   component: () => import("@/components/generalObjectComp/GeneralTableComp.vue"),
    //   props: {
    //     ...GeneralTableCompProps,
    //   },
    //   meta: {
    //     requiresAuth: true,
    //     permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //     groupes: "management"
    //   },
    // },

    ///////////////////// ui <Sessions>///////////////////////
    {
        path: "/ui/club/session/group/trainings",
        name: "GroupTrainingUi",
        component: () => import("../views/ui/sessions/player/training/GroupTrainingUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/session/group/fitness",
        name: "GroupFitnessUi",
        component: () => import("../views/ui/sessions/player/fitness/GroupFitnessUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/session/player/private",
        name: "PlayerPrivateUi",
        component: () => import("../views/ui/sessions/player/private/PlayerPrivateUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/session/player/booking",
        name: "PlayerBookingUi",
        component: () => import("../views/ui/sessions/player/booking/PlayerBookingUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    // {
    //    path: "/ui/club/session/group/mental",
    //    name: "GroupMentalUi",
    //    component: GroupMentalUi,
    //    meta: {
    //      requiresAuth: true,
    //      permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //      groupes: "management"
    //    }
    //  },
    // {
    //   path: "/ui/club/session/group/viewing",
    //   name: "GroupViewingUi",
    //   component: GroupViewingUi,
    //   meta: {
    //     requiresAuth: true,
    //     permissions: ['isSuperuser', 'isStaff', 'isActive'],
    //     groupes: "management"
    //   }
    // },
    {
        path: "/ui/club/session/coach/trainings",
        name: "CoachTrainingUi",
        component: () => import("../views/ui/sessions/coach/training/CoachTrainingUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/session/coach/pricates",
        name: "CoachPrivateUi",
        component: () => import("../views/ui/sessions/coach/private/CoachPrivateUi.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/session/ui/account/rental",
        name: "UserRentalUi",
        component: () => import("../views/ui/sessions/guest/rental/UserRentalUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    ///////////////////// ui <practice>///////////////////////
    {
        path: "/ui/club/practice/subscription",
        name: "SubscriptionUi",
        component: () => import("../views/ui/practice/subscription/SubscriptionUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/practice/booking",
        name: "BookingUi",
        component: () => import("../views/ui/practice/booking/BookingUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/practice/rental",
        name: "RentalUi",
        component: () => import("../views/ui/practice/rental/RentalUi.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/practice/private",
        name: "PrivateUi",
        component: () => import("../views/ui/practice/private/PrivateUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/practice/trainings",
        name: "TrainingUi",
        component: () => import("../views/ui/practice/training/TrainingUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/practice/trainingreschedule",
        name: "TrainingRescheduleUi",
        component: () => import("../views/ui/practice/trainingReschedule/TrainingRescheduleUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/club/practice/fitnessreschedule",
        name: "FitnessRescheduleUi",
        component: () => import("../views/ui/practice/fitnessReschedule/FitnessRescheduleUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },

    {
        path: "/ui/club/practice/fitness",
        name: "FitnessUi",
        component: () => import("../views/ui/practice/fitness/FitnessUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    ///////////////////// ui <The Cclub>///////////////////////
    {
        path: "/ui/:club/clubs",
        name: "ClubsUi",
        component: () => import("../views/ui/seasons/clubs/ClubsUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/timetable/seasons/:club__name/:season__year/:season__name/:from/:to/:coach__first_name/:coach__last_name/:type_session/:type_display/:state",
        name: "SeasonsUi",
        component: () => import("../views/ui/seasons/seasons/SeasonsUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/seasons/groups",
        name: "GroupUi",
        component: () => import("../views/ui/seasons/groups/GroupUi.vue"),

        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/seasons/category",
        name: "CategoryUi",
        component: () => import("../views/ui/seasons/categories/CategoryUi.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/seasons/typegroups",
        name: "TypegroupUiList",
        component: () => import("../views/ui/seasons/typegroups/TypegroupUiList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/seasons/players",
        name: "PlayersUiList.vue",
        component: () => import("../views/ui/seasons/players/PlayersUiList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/ui/seasons/coaches",
        name: "CoachesUiList",
        component: () => import("../views/ui/seasons/coaches/CoachesUiList.vue"),
        meta: {
            requiresAuth: true,
            permissions: ["isSuperuser", "isStaff", "isActive"],
            groupes: "management",
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import(/* webpackChunkName:"help" */ "../views/404/NotFound.vue"),
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // linkActiveClass:'t-c-active-link'
});

// router.afterEach((to, from) => {
//     alert("9999999999999999999999999999999999999999999999999");
//     store._vm.console({ from, title: "form", propss:{color: "green"} }, { to, title: "to" }, { next, title: "next" });
// });
router.beforeEach((to, from, next) => {
    let u = store.state.user;

    // store._vm.console(
    //     { from, title: "form", propss: { color: "yellow" } },
    //     { to, title: "to" },
    //     { next, title: "next" }
    // );
    // store._vm.console(
    //     { from, title: "form", propss: { color: "black" } },
    //     { to, title: "to" },
    //     { next, title: "next" }
    // );
    // store._vm.console(
    //     { from, title: "form", propss: { color: "green" } },
    //     { to, title: "to" },
    //     { next, title: "next" }
    // );
    // store._vm.console({ from, title: "form", propss:{color: "yellow"} });
    // alert(JSON.stringify(u));
    // console.log("dddddddddddd", "store", store, "to:", to, "from:", from, "next:", next, "user:", u);
    if (to.name != "Login" && to.name !== "FinishRegistration" && to.meta.requiresAuth) {
        if (u) {
            // alert(to.meta.requiresAuth);
            // if () {
            if (!u?.email || u.email.indexOf("fake") >= 0) {
                next({ name: "FinishRegistration" });
            } else if (to.query.redirect) {
                next({ name: "Login", query: { redirect: to.path } });
            }
        } else if (to.meta.requiresAuth) {
            next({ name: "Login", query: { redirect: to.path } });
            // next({ name: 'NotFound', query: { redirect: '4jjjjj' }, hash: '#vdvsds' });
        }
        next();
    }
    next(); // This is where it should have been
});

function dateIsValid(dateStr) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateStr.match(regex) === null) {
        return false;
    }

    const date = new Date(dateStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
    }

    return date.toISOString().startsWith(dateStr);
}

export default router;
