<template>
    <component
        :is="wrap_"
        :style="
            itemMode == 'dev' || itemMode == 'all'
                ? {
                      'background-color': randomColor(),
                      border: `10px dashed blue`,
                  }
                : {}
        "
    >
        <div v-if="mode_ == 'all' || mode == 'all' || itemMode == 'all'">
            <h3>--> wrap:<--</h3>
            {{ wrap_ }}
            <h3>--> item:<--</h3>
            <json-viewer :value="item" :expand-depth="5" copyable boxed sort></json-viewer>
            <br />
        </div>
        <slot name="head" v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"> </slot>
        <slot name="head0" v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"></slot>

        <slot name="head1" v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"></slot>
        <slot name="main" v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"></slot>

        <template v-if="!item_"></template>
        <template v-else-if="typeof item_ == 'boolean'">
            <component is="VCheckbox" v-bind="{ disabled: true }" v-model="item_"> </component>
        </template>
        <template v-else-if="typeof item_ == 'string'">
            <WBHtml :html="item_" v-if="item_.includes('[[') && item_.includes(']]')"></WBHtml>
            <template v-else>{{ item_.replace("~", "") }}</template>
        </template>
        <template v-else-if="typeof item_ == 'number'">{{ item_ }} </template>

        <template v-else-if="Array.isArray(item_)">
            <template
                v-if="
                    item_.length > 1 &&
                    !Array.isArray(item_[0]) &&
                    item_[0] instanceof Object &&
                    item_[0].wrap &&
                    item_[0].wrap.includes('~')
                "
            >
                <template v-if="!Array.isArray(item_[0]) && item_[0] instanceof Object && item_[0].props.load">
                    ppppppp
                    <!-- {{ item_[0] }} -->
                    <div class="text-center" v-if="item_[0].props.load instanceof Object">
                        <WBC
                            :item.sync="item_[0].props.load"
                            :wrap="item_[0].props.load.wrap ? item_[0].props.load.wrap : 'div'"
                        >
                        </WBC>

                        <!-- <v-progress-circular indeterminate size="100" color="red" class="ma-10"></v-progress-circular> -->
                    </div>
                    <div class="text-center" v-else-if="item_[0].props.load">
                        <v-progress-circular indeterminate size="100" color="red" class="ma-10"></v-progress-circular>
                    </div>
                </template>

                <WBC
                    :item.sync="item_[0]"
                    :wrap="
                        !Array.isArray(item_[0]) && item_[0] instanceof Object && item_[0].wrap ? item_[0].wrap : wrap_
                    "
                    v-else-if="!Array.isArray(item_[0]) && item_[0] instanceof Object && !item_[0].props.hide"
                    v-bind="
                        mode_ == 'dev' ||
                        mode_ == 'all' ||
                        mode == 'dev' ||
                        mode == 'all' ||
                        item_[0].mode == 'dev' ||
                        item_[0].mode == 'all'
                            ? {
                                  class: 'text-center  m-2',
                                  style: {
                                      'background-color': randomColor(),
                                      border: `10px dashed blue`,
                                  },
                              }
                            : {}
                    "
                >
                    <template #footer0>
                        <WBC
                            :item="item_.slice(1)"
                            :wrap="
                                item_.length > 1 &&
                                item_[0] instanceof Object &&
                                item_[0].wrap &&
                                item_[0].wrap.includes('~')
                                    ? item_[0].wrap.replace('~', '')
                                    : wrap_
                            "
                            v-if="item_.length > 1"
                            v-bind="
                                mode_ == 'dev' ||
                                mode_ == 'all' ||
                                mode == 'dev' ||
                                mode == 'all' ||
                                item_[0].mode == 'dev' ||
                                item_[0].mode == 'all'
                                    ? {
                                          class: 'text-right  m-2',
                                          style: { border: `1px dashed yellow`, 'font-size': '100%' },
                                      }
                                    : {}
                            "
                        >
                        </WBC>
                    </template>
                </WBC>
            </template>

            <template v-else>
                <template v-for="(anItem, i) of item_">
                    <WBC
                        :item="anItem"
                        v-bind="
                            mode_ == 'dev' ||
                            mode_ == 'all' ||
                            mode == 'dev' ||
                            mode == 'all' ||
                            itemMode == 'dev' ||
                            itemMode == 'all'
                                ? {
                                      class: 'text-left',
                                      style: {
                                          'background-color': 'grey',
                                          'border-bottom-width': 'thick',

                                          border: ` solid black `,
                                      },
                                  }
                                : {}
                        "
                    >
                    </WBC>
                </template>
            </template>
        </template>
        <template v-else-if="!item_ instanceof Object"> NEW TYPE TO ADD TO WBC {{ item_ }} </template>
        <template v-else>
            <template v-if="item_.comp && item_.props && item_.props instanceof Object && !item.props.hide">
                <template v-if="!Array.isArray(item_) && item_.props.load">
                    <div class="text-center">
                        <!-- <v-progress-circular indeterminate size="50" color="grey" class="ma-5"></v-progress-circular> -->
                        <div class="text-center" v-if="item_.props.load instanceof Object">
                            <!-- {{ item_.props.load }} -->
                            <WBC
                                :item.sync="item_.props.load"
                                :wrap="item_.props.load.wrap ? item_.props.load.wrap : 'div'"
                            >
                            </WBC>

                            <!-- <v-progress-circular indeterminate size="100" color="red" class="ma-10"></v-progress-circular> -->
                        </div>
                        <div class="text-center" v-else-if="item_.props.load">
                            <v-progress-circular
                                indeterminate
                                size="100"
                                color="red"
                                class="ma-10"
                            ></v-progress-circular>
                        </div>
                    </div>
                </template>
                <WBLink v-else v-bind="!Array.isArray(item_) && item_.to ? { to: item_.to } : {}">
                    <slot
                        name="headers"
                        v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"
                        v-if="item_.props.headers"
                    >
                        <WBC :item="item_.props.headers"></WBC>
                    </slot>
                    <template v-if="Array.isArray(item_.comp)">
                        <!-- {{ compsListToObject.comps[0] }} -->
                        <WBC :item="compsListToObject.comps"> </WBC>
                    </template>
                    <template
                        v-else-if="
                            typeof item_.comp === 'string' &&
                            (item_.comp.includes('|') ||
                                item_.comp.includes('+') ||
                                item_.comp.includes('(') ||
                                item_.comp.includes(')'))
                        "
                    >
                    </template>
                    <template v-else-if="item_.props && !item_.props.hide">
                        <template v-if="'VCheckbox' == item_.comp">
                            <component
                                :is="item_.comp"
                                v-bind="item_.props"
                                v-model="item_.props.val"
                                v-on="item_.events || {}"
                            >
                            </component>
                        </template>
                        <template v-else-if="item_.comp == 'VSelect'">
                            <component
                                :is="item_.comp"
                                v-bind="item_.props"
                                v-model="item_.props.val"
                                :items="item_.props.items"
                                v-on="item_.events || {}"
                                :key="item_.props.key"
                                @change="
                                    typeof item_.props.to === 'function'
                                        ? vSelectChange(item_.props.to(item_.props.val))
                                        : vSelectChange(item_.props.to) || {}
                                "
                            >
                            </component>
                        </template>
                        <template v-else-if="'VTextField' == item_.comp">
                            <component
                                :is="item_.comp"
                                v-bind="item_.props"
                                v-model="item_.props.val"
                                v-on="item_.events || {}"
                            >
                            </component>
                        </template>
                        <component
                            :is="item_.comp"
                            v-bind="item_.props"
                            v-else-if="item_.comp == 'VIcon'"
                            v-on="item_.events || {}"
                        >
                            {{ item_.props.html }}
                        </component>

                        <component
                            :is="item_.comp || 'template'"
                            v-bind="item_.props"
                            v-else-if="item_.props.html"
                            v-model="item_.props.val"
                            v-on="item_.events || {}"
                            :key="item_.props.key"
                        >
                            <WBLink v-bind="item_.hprops ? { to: item_.hprops.to } : {}">
                                <WBHtml
                                    :html="item_.props.html"
                                    v-bind="item_.hprops ? item_.hprops.props : {}"
                                ></WBHtml>
                            </WBLink>
                        </component>
                        <component
                            :is="item_.comp || 'template'"
                            v-bind="item_.props"
                            v-else=""
                            v-on="item_.events ? item_.events : {}"
                        >
                            <!-- <slot></slot> -->
                        </component>
                    </template>
                    <slot
                        name="footers"
                        v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"
                        v-if="item_.props.footers"
                    >
                        <WBC :item="item_.props.footers"></WBC>
                    </slot>
                </WBLink>
            </template>

            <WBC
                :item="Object.values(item_)"
                v-else-if="item_.props instanceof Object && !item.props.hide"
                v-bind="item_.props"
            ></WBC>
            <WBC :item="Object.values(item_)" v-else-if="!item_.props || !(item_.props instanceof Object)"></WBC>
        </template>
        <slot name="footer" v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"> </slot>
        <slot name="footer0" v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"></slot>
        <slot name="footer1" v-bind="{ item_, func: (obj, fun) => callBack(obj, fun) }"></slot>
    </component>
</template>
<script>
// import Vuex from 'vuex'
// import VuetifyJetValidator from '@/rules/MyRules.js'
// const validator = new VuetifyJetValidator();
// import * as vuetifyComponents from "vuetify/lib";

export default {
    name: "WBC",
    props: {
        wrap: {
            type: String,
            default: () => {
                return "div";
            },
        },

        item: {
            default: () => {
                return null;
            },
        },
        mode: {
            default: () => {
                return "prod";
            },
        },
    },
    data() {
        return {
            item_: null,
            wrap_: "div",
        };
    },
    methods: {
        vSelectChange(generalAdress) {
            if (typeof generalAdress == "string") {
                if (generalAdress.startsWith("https://") || generalAdress.startsWith("http://")) {
                    window.open(generalAdress);
                } else {
                    this.$router.push(generalAdress);
                }
            } else if (!Array.isArray(generalAdress) && generalAdress instanceof Object) {
                let l = this.$router.resolve(generalAdress);
                if (l.resolved.matched.length > 0) {
                    this.$router.push(generalAdress);
                }
            }
        },

        callBack(obj, fun) {
            return fun(obj);
        },

        compsToObject(obj, source) {
            if (Array.isArray(obj)) {
                let realObject;
                realObject = obj.map((e) => {
                    if (Array.isArray(e)) {
                        return this.compsToObject(e, source);
                    } else if (typeof e == "string") {
                        let theArrayObjs = [];
                        let wrap = "div";
                        let key = e.replace("~", "");
                        if (Array.isArray(source[key])) {
                            source[key].forEach((h) => {
                                let wrap0 = source[key][0];
                                let firstWrap =
                                    wrap0[0] == "<" && wrap0[wrap0.length - 1] == ">"
                                        ? source[key][0].replace("<", "").replace(">", "")
                                        : "span";
                                if (!(h[0] == "<" && h[h.length - 1] == ">")) {
                                    theArrayObjs.push({
                                        comp: firstWrap.replace("~", ""),
                                        wrap: firstWrap.replace("li", "div"),
                                        props: {
                                            html: h,
                                            key: this.randomKey("key--"),
                                        },
                                    });
                                }
                            });
                            return theArrayObjs;
                        } else if (source[key] instanceof Object) {
                            let obj = {
                                props: {
                                    key: this.randomKey("key-obj-"),
                                },
                                ...source[key],
                            };
                            if (source[key].wrap) {
                                obj["wrap"] = `${source[key].wrap}${e.includes("~") ? "~" : ""}`;
                            } else if (source[key].comp) {
                                obj["wrap"] = `${source[key].comp}${e.includes("~") ? "~" : ""}`;
                            }
                            return obj;
                        } else if (source[key]) {
                            return {
                                comp: "div",
                                // wrap: "~li",
                                props: {
                                    html: source[key],
                                    key: this.randomKey("key-str-"),
                                },
                                wrap: `div${e.includes("~") ? "~" : ""}`,
                            };
                        } else {
                            return {
                                comp: key,
                                wrap: `${key}${e.includes("~") ? "~" : ""}`,
                                props: {
                                    hide: !true,
                                    key: this.randomKey("key-"),
                                },
                            };
                        }
                    }
                });
                return realObject;
            }
        },
        arraysToObj() {
            // this.globalWrap = this.wrap;
            let wrap_ = this.wrap;
            let item_ = this.item_;
            if (Array.isArray(this.item_)) {
                this.item_ = this.item_.filter(
                    (e) => e != [] && e != "[]" && e != "undefined" && e != {} && e != "{}" && e != "null"
                );

                let arrObj = [];
                let e = this.item_[0];
                let thelWrap = wrap_;
                this.item_.forEach((e) => {
                    if (typeof e == "string" && e[0] == "<" && e[e.length - 1] == ">") {
                        thelWrap = e.replace("<", "").replace(">", "");
                        // this.globalWrap = e.replace("<", "").replace(">", "");
                        // this.item_.splice(0, 1);
                    } else if (typeof e == "string" && !e.includes("<")) {
                        arrObj.push({
                            comp: thelWrap.replace("~", ""),
                            // wrap: `div${thelWrap.includes("~") ? "~" : ""}`,
                            wrap: thelWrap.replace("li", "div"),
                            props: {
                                key: this.randomKey("key-obj-"),
                                html: e,
                            },
                        });
                    } else {
                        arrObj.push(e);
                    }
                });

                this.item_ = arrObj;
            }
        },
        randomColor() {
            var letters = "0123456789ABCDEF";
            var color = "#";
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        randomKey(par, integer = 1000) {
            return par + Math.floor(Math.random() * integer);
        },
    },
    watch: {
        item_: function (value) {
            this.$emit("update:item", value);
        },
    },
    computed: {
        mode_: () => "prod",
        itemMode() {
            if (!Array.isArray(this.item_) && this.item_ instanceof Object) {
                return this.item_?.props?.mode || "prod";
            }
            return "prod";
        },
        compsListToObject() {
            let compsList = this.item_.comp;
            let realObject = [];
            realObject = this.compsToObject(compsList, this.item_);
            return {
                comps: realObject,
            };
        },
    },
    components: {},
    mounted() {
        this.item_ = this.item;
        // this.globalWrap = this.wrap;
        this.wrap_ = this.wrap.replace("~", "");
        if (!Array.isArray(this.item_) && this.item_ instanceof Object && this.item_?.props?.wrap) {
            this.wrap_ = this.item_.props.wrap;
        }
        if (this.generalVal) {
            this.item_ = this.generalVal(this.item_);
        }
        this.item_ = this.EventFoncArgRecursive(this.item_);
        this.arraysToObj();
        if (this.item_ instanceof Object && this.item_.header) {
            delete this.item_.header;
        }
    },
};
</script>
<style lang="css" scoped></style>
