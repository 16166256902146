<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
    <slot />
  </a>
  <router-link v-else-if="isInternalLink" :to="to" custom>
    <slot />
  </router-link>
  <span v-else>
    <slot /></span>
</template>
<script>
  // import { RouterLink } from 'vue-router'
  // import VueRouter from "vue-router";
  export default {
    name: 'WBLink',
    inheritAttrs: false,
    props: {
      // add @ts-ignore if using TypeScript
      // ...VueRouter.props,
      to: {},
      inactiveClass: String,
    },
    computed: {
      isExternalLink() {
        return typeof this.to === 'string' //&& (this.to.startsWith('http')||this.to.startsWith('www.'))
      },
      isInternalLink() {
        return typeof this.to === 'object' //&& (this.to.startsWith('http')||this.to.startsWith('www.'))
      },
    },
  }

</script>
<style lang="css" scoped>
</style>
