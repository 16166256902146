import WBC from "./src/components/WBC";
import WBHtml from "./src/components/WBHtml";
import WBLink from "./src/components/WBLink";

export default {
    install: (app, options = {}, mod = "prod") => {
        WBC.computed = { ...WBC.computed, ...options.computed };
        WBC.computed.mode_ = () => mod;
        WBC.components = Object.assign(options.components, app.options.components);
        app.component("WBC", WBC);
        app.component("WBHtml", WBHtml);
        app.component("WBLink", WBLink);
    },
    WBC,
    WBHtml,
    WBLink,
};
